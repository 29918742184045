import * as actionTypes from "./actionTypes";
import {
  getSystemTablesAPI,
  getSystemTableFormAPI,
  createSystemTableFormAPI,
  updateSystemTableFormAPI,
  deleteSystemTableFormAPI,
  executeSystemTableFormAPI,
  downloadSystemTableExcelApi,
  importSystemTableExcelApi,
  changeCheckSystemTableFormAPI,
  getUserTablesAPI,
  getUserTableFormAPI,
  createUserTableFormAPI,
  updateUserTableFormAPI,
  deleteUserTableFormAPI,
  executeUserTableFormAPI,
  downloadUserTableExcelApi,
  importUserTableExcelApi,
  changeCheckUserTableFormAPI,
  uploadSystemTableFormFileAPI,
  uploadUserTableFormFileAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  let duration = 5000;
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      duration = null;
      errorString = `No puede existir más de un registro que comparta los siguientes campos: ${e.response?.data?.duplicatedFields?.join(', ')}`;
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar el registro porque está en uso';
      break;
    case "DETAIL_IN_USE":
      errorString = 'No es posible eliminar el registro porque la tabla detalle asociada tiene control de integridad';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration }));
}

export const getTablesAction = (isSystemTables) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    const apiToUse = isSystemTables ? getSystemTablesAPI : getUserTablesAPI;

    let response = await apiToUse();
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getTableFormAction = (isSystemTables, code, codver, masterRowInternalCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FORM });
  try {
    const apiToUse = isSystemTables ? getSystemTableFormAPI : getUserTableFormAPI;
    const query = `?code=${code}&codver=${codver}${masterRowInternalCode ? `&masterRowInternalCode=${masterRowInternalCode}` : ''}`;

    let response = await apiToUse(query);
    let gridData = response.data && response.data.dataGrid;
    let addCodemps = response.data && response.data.addCodemps;
    let formData = response.data && response.data.data;
    let fields = response.data && response.data.fields;
    let process = response.data && response.data.process;
    let detailTable = response.data && response.data.detailTable;
    let dateFormat = response.data && response.data.dateFormat;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_FORM_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FORM_FULFILLED,
      payload: {
        gridData,
        formData,
        fields,
        process,
        dateFormat,
        detailTable,
        addCodemps,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FORM_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteTableFormAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    const apiToUse = isSystemTables ? deleteSystemTableFormAPI : deleteUserTableFormAPI;

    let response = await apiToUse(dataToSend);
    let gridData = response.data && response.data.dataGrid;
    let formData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { gridData, formData },
    });

    dispatch(openSnackbarAction({ msg: "Los registros se han eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createTableFormAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    const apiToUse = isSystemTables ? createSystemTableFormAPI : createUserTableFormAPI;

    let response = await apiToUse(dataToSend);
    let gridData = response.data && response.data.dataGrid;
    let formData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { gridData, formData },
    });

    dispatch(openSnackbarAction({ msg: "El registro se ha creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateTableFormAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    const apiToUse = isSystemTables ? updateSystemTableFormAPI : updateUserTableFormAPI;
    let response = await apiToUse(dataToSend);
    let gridData = response.data && response.data.dataGrid;
    let formData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { gridData, formData },
    });

    dispatch(openSnackbarAction({ msg: "El registro se actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const executeTableFormAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.EXECUTE });
  try {
    const apiToUse = isSystemTables ? executeSystemTableFormAPI : executeUserTableFormAPI;

    let response = await apiToUse(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.EXECUTE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.EXECUTE_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: "El proceso se ha iniciado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.EXECUTE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadTableExcelAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    const apiToUse = isSystemTables ? downloadSystemTableExcelApi : downloadUserTableExcelApi;

    let response = await apiToUse(dataToSend);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importTableExcelAction = (isSystemTables, dataToSend, tableCode, codver) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);
    formData.append('tableCode', tableCode);
    formData.append('codver', codver);

    const apiToUse = isSystemTables ? importSystemTableExcelApi : importUserTableExcelApi;

    let response = await apiToUse(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));
      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));
      return response;
    }
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const changeCheckTableFormAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    const apiToUse = isSystemTables ? changeCheckSystemTableFormAPI : changeCheckUserTableFormAPI;

    let response = await apiToUse(dataToSend);
    let gridData = response.data && response.data.dataGrid;
    let formData = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { gridData, formData },
    });

    dispatch(openSnackbarAction({ msg: "El registro se ha actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const uploadSystemTableFormFileAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.FORM_FILE_UPLOAD });
  try {
    const apiToUse = isSystemTables ? uploadSystemTableFormFileAPI : uploadUserTableFormFileAPI;

    let response = await apiToUse(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.FORM_FILE_UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.FORM_FILE_UPLOAD_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.FORM_FILE_UPLOAD_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};