import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (country, data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "name", "classification", "address", "previredCode", "lreCode"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z0-9]+[A-Z0-9_]*/;

    let schema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      name: yup.string().required(),
      classification: yup.string().required(),
      address: yup.string().max(100),
      previredCode: yup.string(),
      lreCode: yup.string()
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = { ...formData };

      if (!isCreate) {
        dataToSend = { ...dataToSend, internalCode: data.internalCode };
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
