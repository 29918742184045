import NAME from "./constants";

export const RESET_STATE = NAME + "/RESET_STATE";

export const GET = NAME + "/GET";
export const GET_REJECTED = NAME + "/GET_REJECTED";
export const GET_FULFILLED = NAME + "/GET_FULFILLED";

export const GET_FORM = NAME + "/GET_FORM";
export const GET_FORM_REJECTED = NAME + "/GET_FORM_REJECTED";
export const GET_FORM_FULFILLED = NAME + "/GET_FORM_FULFILLED";

export const DELETE = NAME + "/DELETE";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";

export const CREATE = NAME + "/CREATE";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";

export const MODIFY = NAME + "/MODIFY";
export const MODIFY_REJECTED = NAME + "/MODIFY_REJECTED";
export const MODIFY_FULFILLED = NAME + "/MODIFY_FULFILLED";

export const EXECUTE = NAME + "/EXECUTE";
export const EXECUTE_REJECTED = NAME + "/EXECUTE_REJECTED";
export const EXECUTE_FULFILLED = NAME + "/EXECUTE_FULFILLED";

export const DOWNLOAD = NAME + "/DOWNLOAD";
export const DOWNLOAD_REJECTED = NAME + "/DOWNLOAD_REJECTED";
export const DOWNLOAD_FULFILLED = NAME + "/DOWNLOAD_FULFILLED";

export const UPLOAD = NAME + "/UPLOAD";
export const UPLOAD_REJECTED = NAME + "/UPLOAD_REJECTED";
export const UPLOAD_FULFILLED = NAME + "/UPLOAD_FULFILLED";

export const FORM_FILE_UPLOAD = NAME + "/FORM_FILE_UPLOAD";
export const FORM_FILE_UPLOAD_REJECTED = NAME + "/FORM_FILE_UPLOAD_REJECTED";
export const FORM_FILE_UPLOAD_FULFILLED = NAME + "/FORM_FILE_UPLOAD_FULFILLED";