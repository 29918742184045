import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "@icarius-common/loader";
import { getTheme } from "../../login/selectors";
import { getTableFormAction, getTablesAction } from "../actions";
import { getTables, getIsLoading } from "../selectors";
import GroupSelector from "./groupSelector";
import { RESET_STATE } from "../actionTypes";
import GridScreen from "./gridScreen";
import { getAppColor } from "src/app/selectors";
import paths from "@icarius-localization/paths";

const SystemAndUserTablesContainer = ({ history, location }) => {

  const [table, setTable] = useState(null);
  const [hideGoBack, setHideGoBack] = useState(false);
  const [masterTableData, setMasterTableData] = useState(null);

  const dispatch = useDispatch();
  const theme = useSelector(getTheme);
  const tables = useSelector(getTables);
  const isLoading = useSelector(getIsLoading);
  const color = useSelector(getAppColor);

  const isSystemTables = location.pathname === paths.systemTables;

  const handleOpenGrid = useCallback((tableToSet, masterRowInternalCode) => {
    dispatch(getTableFormAction(isSystemTables, tableToSet.code, tableToSet.codver, masterRowInternalCode))
      .then((res) => {
        res?.status === 200 && setTable(tableToSet);
      })
  }, [dispatch, isSystemTables])

  const handleCloseGrid = () => {
    if (masterTableData) {
      // si tiene masterTableData, al tocar volver me tiene que abrir esa grilla
      history.push({
        path: isSystemTables ? paths.systemTables : paths.userTables,
        search: `code=${masterTableData.code}&codver=${masterTableData.codver}&hideGoBack=${hideGoBack ? 'Y' : 'N'}`,
      })
      setMasterTableData(null);
    } else {
      setTable(null);
    }
  }

  useEffect(() => {
    dispatch(getTablesAction(isSystemTables));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, isSystemTables])

  useEffect(() => {
    // si tengo la data del get inicial, y tengo parametros de busqueda en la url, agarro esos datos y hago automaticamente la seleccion de tabla
    if (Boolean(Object.keys(tables).length) && location.search) {
      const codeParams = new URLSearchParams(location.search).get("code");
      const codverParams = new URLSearchParams(location.search).get("codver");
      const hideGoBackParams = new URLSearchParams(location.search).get("hideGoBack");
      // param que llegan solo desde tabla maestra
      const masterRowInternalCodeParams = new URLSearchParams(location.search).get("masterRowInternalCode");
      const codeMasterTableParams = new URLSearchParams(location.search).get("codeMasterTable");
      const codverMasterTableParams = new URLSearchParams(location.search).get("codverMasterTable");

      const hasMasterTable = codeMasterTableParams && codverMasterTableParams && masterRowInternalCodeParams;

      if (hasMasterTable) {
        // la data de la tabla maestra desde donde se viene, si no tiene datos, es que no viene de tabla maestro
        setMasterTableData({
          code: codeMasterTableParams,
          codver: codverMasterTableParams,
          masterRowInternalCode: masterRowInternalCodeParams,
        });
      }

      if (hideGoBackParams === "Y") {
        setHideGoBack(true);
      }

      let tableToUse;
      if (hasMasterTable) { // si tiene tabla maestro, buscar esa y obtener de ahi los datos de la detail (la detail solo sale dentro de la master)
        tableToUse = Object.values(tables).flat().find(item => item.code === codeMasterTableParams && item.codver === codverMasterTableParams)?.detailTable;
      } else {
        tableToUse = Object.values(tables).flat().find(item => item.code === codeParams && item.codver === codverParams);
      }

      if (tableToUse) {
        // limpio los parametros para que en proximos render no haga el useEffect de nuevo
        history.replace({
          pathname: location.pathname,
          search: '',
        });

        handleOpenGrid(tableToUse, masterRowInternalCodeParams);
      }
    }
  }, [location.pathname, location.search, history, handleOpenGrid, tables])

  return (
    <section id={"my-people-page"}>
      <Loader open={isLoading} />
      {
        Boolean(table) ?
          <GridScreen
            handleGoBack={handleCloseGrid}
            tableData={table}
            masterRowInternalCode={masterTableData?.masterRowInternalCode}
            isSystemTables={isSystemTables}
            hideGoBack={hideGoBack}
          />
          :
          !isLoading &&
          <GroupSelector
            isSystemTables={isSystemTables}
            color={color}
            data={tables}
            theme={theme}
            handleOpenGrid={handleOpenGrid}
          />
      }
    </section>
  );
}

export default SystemAndUserTablesContainer;
