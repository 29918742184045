import { getAvailableTotems, addNewTotem, deleteTotem, updateTotem } from "@icarius-connection/api";
import {
  GET_TOTEMS,
  GET_TOTEMS_FULFILLED,
  GET_TOTEMS_REJECTED,
  ADD_TOTEM,
  ADD_TOTEM_FULFILLED,
  ADD_TOTEM_REJECTED,
  DELETE_REQUEST,
  DELETE_REQUEST_REJECTED,
  DELETE_REQUEST_FULFILLED,
  NEW_TOTEM,
  DELETE_TOTEM_NOT_CREATED,
  UPDATE_TOTEM_REJECTED, UPDATE_TOTEM, UPDATE_TOTEM_FULFILLED,
} from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { setKeyValue, getValueFromKey, deleteKey, isKeyPresent } from "@icarius-utils/localStorage";

export const getTotemsFromServer = () => async (dispatch) => {
  dispatch({ type: GET_TOTEMS });
  try {
    let response = await getAvailableTotems();

    let totems = response.data && response.data.totems;
    let workplaces = response.data && response.data.workplaces;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: GET_TOTEMS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: GET_TOTEMS_FULFILLED,
      payload: {
        totems: totems,
        workplaces: workplaces,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: GET_TOTEMS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const addTotem = (data) => async (dispatch) => {
  try {
    dispatch({ type: ADD_TOTEM });

    let response = await addNewTotem({
      code: data.code,
      workplace_id: data.workplace,
    });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: ADD_TOTEM_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("totemAlreadyExists"), severity: "error" }));
      return;
    }
    dispatch(openSnackbarAction({ msg: getLocalizedString("totemCorrectlyAdded"), severity: "success" }));
    setKeyValue("assistance-totem-id", data.code);
    setKeyValue("assistance-totem-workplace", data.workplaceName);
    setKeyValue("assistance-totem-society-name", data.societyName);
    setKeyValue("assistance-totem-timezone", response.data.timezone);


    dispatch({
      type: ADD_TOTEM_FULFILLED, payload: {
        code: data.code,
        workplace_id: data.workplace,
        index: data.index,
      }
    });
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: ADD_TOTEM_REJECTED });
  }
};

export const updateTotemAction = (data) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TOTEM });

    let response = await updateTotem({
      code: data.code,
      workplace_id: data.workplace,
    });

    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: UPDATE_TOTEM_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("totemAlreadyExists"), severity: "error" }));
      return;
    }

    if (isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-id") === data.code) {
      setKeyValue("assistance-totem-workplace", data.workplaceName);
      setKeyValue("assistance-totem-society-name", data.societyName);
      setKeyValue("assistance-totem-timezone", "una");
    }
    dispatch(openSnackbarAction({ msg: getLocalizedString("totemCorrectlyUpdated"), severity: "success" }));
    dispatch({
      type: UPDATE_TOTEM_FULFILLED, payload: {
        code: data.code,
        workplace_id: data.workplace,
      }
    });
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: UPDATE_TOTEM_REJECTED });
  }
};

export const newTotem = () => dispatch => {
  dispatch({ type: NEW_TOTEM });
};

export const deleteTotemNotCreated = (index) => dispatch => {
  dispatch({ type: DELETE_TOTEM_NOT_CREATED, payload: index });
  dispatch(openSnackbarAction({ msg: getLocalizedString("totemCorrectlyDeleted"), severity: "success" }));
};

export const deleteTotemAction = data => async (dispatch) => {
  const totemCode = { code: data.code };
  try {
    dispatch({ type: DELETE_REQUEST });

    let response = await deleteTotem(totemCode);

    if (response.data.status === "OK") {
      dispatch({ type: DELETE_REQUEST_FULFILLED, payload: { code: data.code, index: data.index } });
      if (getValueFromKey("assistance-totem-id") === data.code) {
        deleteKey("assistance-totem-id");
        deleteKey("assistance-totem-workplace");
        deleteKey("assistance-totem-society-name");
        deleteKey("assistance-totem-timezone");
      }
      dispatch(openSnackbarAction({ msg: getLocalizedString("totemCorrectlyDeleted"), severity: "success" }));
      return response;
    }

    dispatch({ type: DELETE_REQUEST_REJECTED });
    return response;
  } catch (e) {
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    dispatch({ type: DELETE_REQUEST_REJECTED });
  }
};
