import * as yup from 'yup';
import { useState } from "react";
import {
  createDateFromDDMMYYYY,
  createDateFromHHMM,
  createDateFromYYYYMM,
  formatDateHHMM,
  formatDateYYYYMM,
  formatDateYYYYMMDD,
} from "@icarius-utils/date";
import { dataTypes, componentTypes } from '@icarius-pages/systemAndUserTables/constants';

const dateDataTypes = [dataTypes.date, dataTypes.time, dataTypes.period];
const textDataTypes = [
  dataTypes.alfanumeric,
  dataTypes.multiline,
  dataTypes.integer,
  dataTypes.twoDecimals,
  dataTypes.threeDecimals,
  dataTypes.fourDecimals,
  dataTypes.fiveDecimals,
];

const useHandleForm = (data, fields, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      const fieldItem = fields[fieldName];
      if (isCreate) {
        if (fieldItem.component === componentTypes.list) {
          if (fieldItem.required && fieldItem.items.length === 1) return fieldItem.items[0]; // si es obligatorio y tiene una opcion sola, autosetear esa
          return null; // sino, inicializar en null
        }

        if (textDataTypes.includes(fieldItem.type)) return '';
        if (dateDataTypes.includes(fieldItem.type)) return null;
        if ([dataTypes.boolean].includes(fieldItem.type)) return false;
      }

      if (fieldItem.component === componentTypes.list) return fieldItem.items.find((item) => item.key === data[fieldName]);
      if (dataTypes.date === fieldItem.type) return data[fieldName] ? createDateFromDDMMYYYY(data[fieldName]) : null;
      if (dataTypes.time === fieldItem.type) return data[fieldName] ? createDateFromHHMM(data[fieldName]) : null;
      if (dataTypes.period === fieldItem.type) return data[fieldName] ? createDateFromYYYYMM(data[fieldName]) : null;

      return data[fieldName];
    }

    const fieldNames = Object.keys(fields);

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData);

  const dataIsValid = async () => {

    const getSchema = () => {
      const schema = {};

      Object.keys(fields).forEach((fieldKey) => {
        const fieldItem = fields[fieldKey];

        if (fieldItem.component === componentTypes.list) {// si usa el autocomplete, va a llegar como objeto aca
          if (fieldItem.required) {
            schema[fieldKey] = yup.object().shape({
              'key': yup.string().required().required(),
              'value': yup.string().required().required(),
            }).required();
            return;
          }

          schema[fieldKey] = yup.object().shape({
            'key': yup.string(),
            'value': yup.string(),
          }).nullable();
          return;
        }

        if (textDataTypes.includes(fieldItem.type)) {
          fieldItem.required ? schema[fieldKey] = yup.string().required() : schema[fieldKey] = yup.string();
          return;
        }

        if (dateDataTypes.includes(fieldItem.type)) {
          fieldItem.required ? schema[fieldKey] = yup.date().required() : schema[fieldKey] = yup.date().nullable();
          return;
        }

        if (dataTypes.boolean === fieldItem.type) {
          fieldItem.required ? schema[fieldKey] = yup.bool().required() : schema[fieldKey] = yup.bool();
        }
      });

      return schema;
    }

    const schema = yup.object().shape(getSchema());

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const dataToSend = {
        ...formData,
      };

      //formatear los component === "L"
      const autocompleteComponentKeyFields = Object.keys(fields).filter(key => componentTypes.list === fields[key].component);
      autocompleteComponentKeyFields.forEach(key => {
        dataToSend[key] = dataToSend[key]?.key || null;
      });

      //formatear las variantes de fecha
      const dateTypeKeyFields = Object.keys(fields).filter((key) => dateDataTypes.includes(fields[key].type));

      dateTypeKeyFields.forEach((key) => {
        if (fields[key].type === dataTypes.date) {
          dataToSend[key] = dataToSend[key] ? formatDateYYYYMMDD(dataToSend[key]) : null;
          return;
        }

        if (fields[key].type === dataTypes.time) {
          dataToSend[key] = dataToSend[key] ? formatDateHHMM(dataToSend[key]) : null;
          return;
        }

        if (fields[key].type === dataTypes.period) {
          dataToSend[key] = dataToSend[key] ? formatDateYYYYMM(dataToSend[key]) : null;
        }
      });

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    // cada vez que cambio uno con componente L, fijarme en los demas si alguno tiene filterField con valor igual al fieldName, y a esos limpiarles el valor
    const fieldToChange = Object.values(fields).find((item) => item.code === fieldName);
    if (fieldToChange.component === "L") {
      const fieldsToClear = Object.values(fields).filter((item) => item.filterField === fieldName);
      const clearValues = {};
      fieldsToClear.forEach((field) => clearValues[field.code] = null);

      setFormData({
        ...formData,
        [fieldName]: value,
        ...clearValues,
      })

      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;