import * as actionTypes from "./actionTypes";
import {
  getSystemTablesFieldsDefinitionAPI,
  deleteSystemTableFieldsDefinitionAPI,
  createSystemTableFieldsDefinitionAPI,
  updateSystemTableFieldsDefinitionAPI,
  getUserTablesFieldsDefinitionAPI,
  deleteUserTableFieldsDefinitionAPI,
  createUserTableFieldsDefinitionAPI,
  updateUserTableFieldsDefinitionAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de un campo que comparta el mismo código o nombre";
      break;
    case "DUPLICATED_ORDER":
      errorString = "No puede existir más de un campo que con el mismo número de orden";
      break;
    case "IN_USE_UPDATE_FILTER_FIELD":
      errorString = `No es posible cambiar el componente visual de este campo porque está definido como filtro para el campo: ${e.response?.data?.field || ""}`;
      break;
    case "IN_USE_DELETE_FILTER_FIELD":
      errorString = `No es posible eliminar este campo del editor porque está definido como filtro para el campo: ${e.response?.data?.field || ""}`;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getSystemTablesFieldsDefinitionAction = (isSystemTables, tableCode, codver) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    const apiToUse = isSystemTables ? getSystemTablesFieldsDefinitionAPI : getUserTablesFieldsDefinitionAPI;

    let response = await apiToUse(`?code=${tableCode}&codver=${codver}`);
    let data = response.data && response.data.data;
    let typeList = response.data && response.data.typeList;
    let componentList = response.data && response.data.componentList;
    let gridTypeList = response.data && response.data.gridTypeList;
    let requiredList = response.data && response.data.requiredList;
    let fieldList = response.data && response.data.fieldList;
    let disabledList = response.data && response.data.disabledList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, typeList, componentList, gridTypeList, requiredList, fieldList, disabledList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteSystemTableFieldsDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    const apiToUse = isSystemTables ? deleteSystemTableFieldsDefinitionAPI : deleteUserTableFieldsDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El campo se ha eliminado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createSystemTableFieldsDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    const apiToUse = isSystemTables ? createSystemTableFieldsDefinitionAPI : createUserTableFieldsDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El campo se ha creado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateSystemTableFieldsDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    const apiToUse = isSystemTables ? updateSystemTableFieldsDefinitionAPI : updateUserTableFieldsDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: "El campo se ha actualizado correctamente", severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};