import * as actionTypes from "./actionTypes";

const initialState = {
  tables: {},
  gridData: [],
  formData: [],
  addCodemps: false,
  fields: null,
  process: null,
  isLoading: false,
  dateFormat: 'dd/mm/yyyy',
  isLoadingFileUpload: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        tables: action.payload.data,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_FORM:
      return { ...state, isLoading: true };
    case actionTypes.GET_FORM_FULFILLED:
      return {
        ...state,
        isLoading: false,
        addCodemps: action.payload.addCodemps,
        gridData: action.payload.gridData,
        formData: action.payload.formData,
        fields: action.payload.fields,
        process: action.payload.process,
        dateFormat: action.payload.dateFormat,
      };
    case actionTypes.GET_FORM_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        gridData: action.payload.gridData,
        formData: action.payload.formData,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        gridData: action.payload.gridData,
        formData: action.payload.formData,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        gridData: action.payload.gridData,
        formData: action.payload.formData,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.EXECUTE:
      return { ...state, isLoading: true };
    case actionTypes.EXECUTE_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.EXECUTE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPLOAD:
      return { ...state, isLoading: true };
    case actionTypes.UPLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.FORM_FILE_UPLOAD:
      return { ...state, isLoadingFileUpload: true };
    case actionTypes.FORM_FILE_UPLOAD_FULFILLED:
      return { ...state, isLoadingFileUpload: false };
    case actionTypes.FORM_FILE_UPLOAD_REJECTED:
      return { ...state, isLoadingFileUpload: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
