import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { RESET_STATE } from "../actionTypes";
import {
  getSystemTablesFieldsDefinitionAction,
  deleteSystemTableFieldsDefinitionAction,
  createSystemTableFieldsDefinitionAction,
  updateSystemTableFieldsDefinitionAction,
} from "../actions";
import {
  getLoading,
  getData,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useTablesFieldsDefinition = (isSystemTables, tableCode, codver) => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null); //seleccionado para editar, borrar o carpeta digital

  const dispatch = useDispatch();
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getSystemTablesFieldsDefinitionAction(isSystemTables, tableCode, codver));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, isSystemTables, tableCode, codver])

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: "¿Desea eliminar la definición de campo de la tabla?",
      onConfirm: () => handleDelete(documents[0]),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedDocument(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedDocument(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedDocument(null);
  }

  const handleDelete = (document) => {
    dispatch(deleteSystemTableFieldsDefinitionAction(isSystemTables, { internalCode: document.internalCode }));
  }

  const handleCreate = (dataToSend) => {
    dispatch(createSystemTableFieldsDefinitionAction(isSystemTables, dataToSend))
      .then((res) => res?.status === 200 && handleCloseCreateEditDialog());
  }

  const handleModify = (dataToSend) => {
    dispatch(updateSystemTableFieldsDefinitionAction(isSystemTables, dataToSend))
      .then((res) => res?.status === 200 && handleCloseCreateEditDialog());
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedDocument,
  }

  const handlers = {
    handleCreate,
    handleModify,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
  }

  return {
    data,
    isLoading,
    state,
    handlers,
  }
}

export default useTablesFieldsDefinition;