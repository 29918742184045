import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, codeList, createCallback, modifyCallback, invalidDataCallback, invalidControlDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'editorActive') {
        return isCreate ? 'N' : data[fieldName];
      }

      if (fieldName === 'importerActive') {
        return isCreate ? 'Y' : data[fieldName];
      }

      if (fieldName === 'withCheck') {
        return isCreate ? false : data[fieldName];
      }

      if (fieldName === 'isDetailTable') {
        return isCreate ? false : data[fieldName];
      }

      return isCreate ? "" : (data[fieldName] || '');
    }

    const fieldNames = [
      'name',
      'code',
      'codver',
      'group',
      'image',
      'accessType',
      'editorActive',
      'importerActive',
      'process',
      'controlCode',
      'relatedTable',
      'relatedTableCode',
      'orderByFields',
      'filterByFields',
      'withCheck',
      'userMenu',
      'isDetailTable',
      'associatedDetailTable',
      'sqlInsert',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const controlCodeRegex = /^(([A-Z]|[a-z])([A-Z]|[a-z]|\d|_){0,18})*$/;
    const relatedTableRegex = /^(([A-Z]|[a-z]|@)([A-Z]|[a-z]|\d|_){0,18})*$/;
    const relatedTableCodeRegex = /^(([A-Z]|[a-z])([A-Z]|[a-z]|\d|_){0,18})*$/;

    const schema = yup.object().shape({
      'name': yup.string().required(),
      'code': yup.string().required(),
      'accessType': yup.string().required(),
      'editorActive': yup.string().required(),
      'importerActive': yup.string().required(),
      'codver': yup.string().required(),
      'group': yup.string().required(),
      'image': yup.string(),
      'process': yup.string(),
      'orderByFields': yup.string(),
      'filterByFields': yup.string(),
      'withCheck': yup.bool(),
      'isDetailTable': yup.bool(),
      'controlCode': yup.string().matches(controlCodeRegex),
      'relatedTable': yup.string().matches(relatedTableRegex),
      'relatedTableCode': yup.string().matches(relatedTableCodeRegex),
      'userMenu': yup.string(),
      'associatedDetailTable': yup.string(),
      'sqlInsert': yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const controlFieldsAreValid = () => {
    const { controlCode, relatedTable, relatedTableCode } = formData;
    if (Boolean(controlCode) && (!Boolean(relatedTable) || !Boolean(relatedTableCode))) return false;
    if (Boolean(relatedTable) && (!Boolean(controlCode) || !Boolean(relatedTableCode))) return false;
    if (Boolean(relatedTableCode) && (!Boolean(controlCode) || !Boolean(relatedTable))) return false;
    return true;
  }

  const submit = async () => {
    if (await dataIsValid()) {
      if (controlFieldsAreValid()) {
        let dataToSend = {
          ...formData,
        };

        isCreate ? createCallback(dataToSend) : modifyCallback({ internalCode: data.internalCode, ...dataToSend });
        return true;
      } else {
        invalidControlDataCallback();
      }
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'code') {
      setFormData({
        ...formData,
        [fieldName]: value,
        name: codeList.find(item => item.key === value)?.value || '',
      });
      return;
    };

    if (fieldName === 'accessType') {
      setFormData({
        ...formData,
        [fieldName]: value,
        withCheck: value === 'ME' ? false : formData.withCheck,
        userMenu: '',
      });
      return;
    }

    // si es detalle, no puede tener tabla asociada ni menu de usuario
    if (fieldName === 'isDetailTable' && value === true) {
      setFormData({
        ...formData,
        [fieldName]: value,
        associatedDetailTable: '',
        userMenu: '',
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
