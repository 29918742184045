import React, { useMemo } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Typography,
  Checkbox,
  InputAdornment,
  IconButton,
  Button,
  Tooltip,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { Autocomplete } from "@material-ui/lab";
import { GetAppIcon, LinkIcon, ImageIcon, DeleteIcon, VisibilityIcon, RoomIcon } from "@icarius-icons/index";
import { downloadFromURL, previewFromURL } from "@icarius-utils/download";
import { makeFile } from "@icarius-utils/fileUpload";
import Loader from "@icarius-common/loader";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { uploadSystemTableFormFileAction } from "../../../actions";
import { getIsLoadingFileUpload } from "../../../selectors";
import { componentTypes } from "../../../constants";
import useHandleForm from "./useHandleForm";

const gridStyle = { padding: "0px 10px" };
const flexStyle = { paddingTop: 12, display: "flex", alignItems: "center", cursor: "pointer" };

const acceptedFormatsFile = [
  'jpg',
  'bmp',
  'png',
  'jpeg',
  'rar',
  'zip',
  'gz',
  'tar',
  'xls',
  'xlsx',
  'txt',
  'rtf',
  'doc',
  'docx',
  'pdf',
  'html',
  'ppt',
  'pptx',
];

const acceptedFormatsImage = [
  'jpg',
  'bmp',
  'png',
  'jpeg',
];

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const HelperTextComponent = ({ children }) => {
  return (
    <Grid container item xs={12} style={{ minHeight: 20 }}>
      <Typography className="whiteText" variant="caption">
        {children}
      </Typography>
    </Grid>
  )
}

const CreateEditDialog = (props) => {

  const {
    open,
    tableData,
    fields,
    level,
    isLoading,
    data,
    handleClose,
    create,
    modify,
    changeCheck,
    isSystemTables,
    classes,
  } = props;

  const dispatch = useDispatch();
  const isLoadingFileUpload = useSelector(getIsLoadingFileUpload);

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, fields, create, modify, openValidationError);

  const handleFileImageUpload = (e, fieldCode) => {
    makeFile(e.target.files[0], (myBase64) => {
      const dataToSend = {
        tableCode: tableData.code,
        codver: tableData.codver,
        code: fieldCode,
        file: myBase64,
      }

      dispatch(uploadSystemTableFormFileAction(isSystemTables, dataToSend))
        .then((resp) => {
          if (resp.status === 200) {
            setFormValue(resp.data.path, fieldCode);
          }
        })
    })
  }

  const getDialogActions = () => {
    const dataToRejectApprove = {
      internalCode: data?.internalCode,
      code: tableData.code,
      codver: tableData.codver,
    }

    const cancelButton = <ButtonDialogAction onClick={handleClose} text={'Cancelar'} />;
    const rejectButton = <ButtonDialogAction onClick={() => changeCheck(dataToRejectApprove, 'R')} text={'Rechazar'} />;
    const approveButton = <ButtonDialogAction isAccept onClick={() => changeCheck(dataToRejectApprove, 'A')} text={'Aprobar'} />;

    const cancelAcceptButtons = (
      <>
        {cancelButton}
        <ButtonDialogAction onClick={submit} isAccept text={'Aceptar'} />
      </>
    );

    if (tableData.editorActive === "V") return null;
    if (isCreate || !tableData.withCheck) return cancelAcceptButtons;

    if (tableData.editorActive === "Y") {
      if (level === 'C' || level === 'M') {
        if (data.vis === "I") return cancelAcceptButtons;
        return cancelButton;
      }

      // si es empleador
      return cancelAcceptButtons;
    }

    if (tableData.editorActive === "M") {
      if (level === 'M' || level === 'E') {
        switch (data.vis) {
          case 'I': return (
            <>
              {cancelButton}
              {rejectButton}
              {approveButton}
            </>
          );
          case 'R': return (
            <>
              {cancelButton}
              {approveButton}
            </>
          );
          case 'A': return (
            <>
              {cancelButton}
              {rejectButton}
            </>
          );
          default: return cancelButton;
        }
      }

      // si es colaborador
      return cancelAcceptButtons;
    }

    return cancelAcceptButtons;
  }

  const getComponent = (field) => {
    const getLabel = () => {
      if (field.disabled === "Y") return `${field.name} (Protegido)`;
      if (field.disabled === "E") return `${field.name} (Protegido en edición)`;
      return field.name;
    }

    const getIsDisabled = () => {
      if (tableData.editorActive === 'V') return true;
      if (tableData.withCheck && tableData.editorActive === 'M' && (level === 'M' || level === 'E')) return true;
      if (field.disabled === "N") return false;
      if (field.disabled === "E" && isCreate) return false;
      return true;
    }

    const getListItems = () => {
      // Si el field tiene filterField, y este tiene valor, y los items tienen el dato para matchear, filtrar segun su valor. Sino, devovler todo el array.
      if (!field.filterField) return field.items;
      if (!formData[field.filterField]) return field.items;
      if (field.items.every((item) => !item.hasOwnProperty(field.filterField))) return field.items;
      return field.items.filter((item) => item[field.filterField] === formData[field.filterField]?.key);
    }

    if (field.hidden) return <></>;

    switch (field.component) {
      case componentTypes.text: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            onChange={(e) => setFormValue(e.target.value, field.code)}
            value={formData[field.code]}
            inputProps={{ maxLength: field.size }}
            fullWidth
            margin={"none"}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.list: return (
        <>
          <Autocomplete
            disabled={getIsDisabled()}
            style={{ width: '100%' }}
            options={getListItems()}
            value={formData[field.code]}
            onChange={(event, newUser) => {
              setFormValue(newUser, field.code);
            }}
            noOptionsText={''}
            getOptionLabel={(item) => item?.value || ''}
            renderInput={(params) => {
              return (
                <TextField
                  required={field.required}
                  label={getLabel()}
                  {...params}
                />
              )
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.multiline: return (
        <>
          <TextField
            style={{ marginTop: 10 }}
            disabled={getIsDisabled()}
            required={field.required}
            fullWidth
            label={getLabel()}
            variant="outlined"
            multiline
            rows={5}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e.target.value, field.code)}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              classes: {
                root: classes.cssOutlinedInput,
                focused: classes.cssFocused,
                notchedOutline: classes.notchedOutline,
              }
            }}
            inputProps={{ spellCheck: 'false' }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.dateWithPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="dd/MM/yyyy"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.timeWithPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <TimePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            minutesStep={5}
            fullWidth
            clearable
            clearLabel={getLocalizedString("clear")}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="HH:mm"
            margin="none"
            ampm={false}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.periodWithPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            views={["month", "year"]}
            format="yyyy/MM"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.integer: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.code]}
            fullWidth
            margin={"none"}
            type="number"
            onChange={(e) => setFormValue(e.target.value.substring(0, field.size), field.code)}
            onBlur={(e) => e.target.value = e.target.value.substring(0, field.size)}
            inputProps={{ step: 1, maxLength: field.size }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.twoDecimals: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.code]}
            fullWidth
            margin={"none"}
            type="number"
            onChange={(e) => setFormValue(e.target.value.substring(0, field.size), field.code)}
            inputProps={{ min: "1", step: "0.01" }}
            onBlur={e => {
              if (e.target.value !== "") {
                e.target.value = Number.parseFloat(e.target.value).toFixed(3);
                setFormValue(e.target.value, field.code);
              }
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.threeDecimals: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.code]}
            fullWidth
            margin={"none"}
            type="number"
            onChange={(e) => setFormValue(e.target.value.substring(0, field.size), field.code)}
            inputProps={{ min: "1", step: "0.001" }}
            onBlur={e => {
              if (e.target.value !== "") {
                e.target.value = Number.parseFloat(e.target.value).toFixed(3);
                setFormValue(e.target.value, field.code);
              }
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.fourDecimals: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.code]}
            fullWidth
            margin={"none"}
            type="number"
            onChange={(e) => setFormValue(e.target.value.substring(0, field.size), field.code)}
            inputProps={{ min: "1", step: "0.0001" }}
            onBlur={e => {
              if (e.target.value !== "") {
                e.target.value = Number.parseFloat(e.target.value).toFixed(4);
                setFormValue(e.target.value, field.code);
              }
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.fiveDecimals: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            value={formData[field.code]}
            fullWidth
            margin={"none"}
            type="number"
            onChange={(e) => setFormValue(e.target.value.substring(0, field.size), field.code)}
            inputProps={{ min: "1", step: "0.00001" }}
            onBlur={e => {
              if (e.target.value !== "") {
                e.target.value = Number.parseFloat(e.target.value).toFixed(5);
                setFormValue(e.target.value, field.code);
              }
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.checkbox: return (
        <>
          <div style={flexStyle} onClick={() => !getIsDisabled() && setFormValue(!Boolean(formData[field.code]), field.code)}>
            <div>
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {getLabel()}
              </Typography>
              {
                field.required &&
                <Typography variant="subtitle1" style={{ marginLeft: 5, display: "inline-block", color: "red" }}>
                  *
                </Typography>
              }
            </div>
            <Checkbox checked={formData[field.code]} />
          </div>
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.dateNoPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            format="dd/MM/yyyy"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.timeNoPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardTimePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            invalidDateMessage=""
            minDateMessage=""
            minutesStep={5}
            fullWidth
            clearable
            clearLabel={getLocalizedString("clear")}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            format="HH:mm"
            margin="none"
            ampm={false}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.periodNoPicker: return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <KeyboardDatePicker
            disabled={getIsDisabled()}
            required={field.required}
            label={getLabel()}
            value={formData[field.code]}
            onChange={(e) => setFormValue(e, field.code)}
            fullWidth
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=""
            minDateMessage=""
            views={["month", "year"]}
            format="yyyy/MM"
            margin="none"
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </MuiPickersUtilsProvider>
      );
      case componentTypes.link:
      case componentTypes.fileLink:
        return (
          <>
            <TextField
              required={field.required}
              disabled={getIsDisabled()}
              label={getLabel()}
              onChange={(e) => setFormValue(e.target.value, field.code)}
              value={formData[field.code]}
              inputProps={{ maxLength: field.size }}
              fullWidth
              margin={"none"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => formData[field.code] && previewFromURL(formData[field.code])}
                    >
                      <LinkIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <HelperTextComponent>
              {field.comment}
            </HelperTextComponent>
          </>
        );
      case componentTypes.imageLink: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            onChange={(e) => setFormValue(e.target.value, field.code)}
            value={formData[field.code]}
            inputProps={{ maxLength: field.size }}
            fullWidth
            margin={"none"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => formData[field.code] && previewFromURL(formData[field.code])}
                  >
                    <ImageIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.geocoordinates: return (
        <>
          <TextField
            required={field.required}
            disabled={getIsDisabled()}
            label={getLabel()}
            onChange={(e) => setFormValue(e.target.value, field.code)}
            value={formData[field.code]}
            inputProps={{ maxLength: field.size }}
            fullWidth
            margin={"none"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => formData[field.code] && previewFromURL(`https://maps.google.com/?q=${formData[field.code]}`)}
                  >
                    <RoomIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <HelperTextComponent>
            {field.comment}
          </HelperTextComponent>
        </>
      );
      case componentTypes.imageInput: return (
        <>
          <Grid item>
            <input
              key={formData[field.code]}
              accept={acceptedFormatsImage.map(item => `.${item}`).join(',')}
              disabled={getIsDisabled()}
              type="file"
              id={field.code}
              style={{ display: "none" }}
              onChange={(e) => handleFileImageUpload(e, field.code)}
            />
            <div style={{ height: 48, display: 'flex', alignItems: 'center' }}>
              <label htmlFor={field.code}>
                <Button
                  variant={"contained"}
                  disableRipple={true}
                  className="whiteText"
                  component={"span"}
                >
                  {getLabel()}
                </Button>
              </label>
              {
                Boolean(formData[field.code]) &&
                <>
                  <img
                    style={{ height: 34, marginLeft: 15 }}
                    src={RESOURCES_ENDPOINT + formData[field.code]}
                    alt={field.name}
                  />
                  <Tooltip title={'Ver'}>
                    <IconButton onClick={() => previewFromURL(RESOURCES_ENDPOINT + formData[field.code])}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    !getIsDisabled() &&
                    <Tooltip title={'Eliminar'}>
                      <IconButton onClick={() =>
                        dispatch(openDialogAction({
                          title: 'Atención',
                          msg: `¿Desea borrar la imagen del campo "${field.name}"?`,
                          onConfirm: () => setFormValue('', field.code),
                        }))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </>
              }
            </div>
            <HelperTextComponent>
              {field.comment}
            </HelperTextComponent>
          </Grid>
        </>
      )
      case componentTypes.fileInput: return (
        <>
          <Grid item>
            <input
              disabled={getIsDisabled()}
              key={formData[field.code]}
              accept={acceptedFormatsFile.map(item => `.${item}`).join(',')}
              type="file"
              id={field.code}
              style={{ display: "none" }}
              onChange={(e) => handleFileImageUpload(e, field.code)}
            />
            <div style={{ height: 48, display: 'flex', alignItems: 'center' }}>
              <label htmlFor={field.code}>
                <Button
                  variant={"contained"}
                  disableRipple={true}
                  className="whiteText"
                  component={"span"}
                >
                  {getLabel()}
                </Button>
              </label>
              {
                Boolean(formData[field.code]) &&
                <>
                  <Tooltip title={'Descargar'}>
                    <IconButton onClick={() => downloadFromURL(RESOURCES_ENDPOINT + formData[field.code], field.name)}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  {
                    !getIsDisabled() &&
                    <Tooltip title={'Eliminar'}>
                      <IconButton onClick={() =>
                        dispatch(openDialogAction({
                          title: 'Atención',
                          msg: `¿Desea borrar el archivo del campo "${field.name}"?`,
                          onConfirm: () => setFormValue('', field.code),
                        }))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </>
              }
            </div>
            <HelperTextComponent>
              {field.comment}
            </HelperTextComponent>
          </Grid>
        </>
      )
      default: return <></>;
    }
  }

  const fieldsKeyArrayWithBlankSpaces = useMemo(() => {
    const keysArray = Object.keys(fields);
    const newKeysArray = [];

    keysArray.forEach((key, index) => {
      newKeysArray.push(key);
      if (index !== keysArray.length - 1) { // si no es el ultimo
        const nextFieldItem = fields[keysArray[index + 1]]

        // si el actual es impar, ver si el proximo es impar, si lo es, agregar un item en el medio
        if (fields[key].order % 2 !== 0) {
          if (nextFieldItem.order % 2 !== 0) { // si el prodximo es impar
            newKeysArray.push(`fixOrder-${fields[key].order + 1}`); // como el proximo es tambien impar, agrego una key antes
          }
        } else {
          // si el actual es par, ver si el proximo es par, si lo es, agregar un item en el medio
          if (nextFieldItem.order % 2 === 0) { // si el prodximo es par
            newKeysArray.push(`fixOrder-${fields[key].order + 1}`); // como el proximo es tambien par, agrego una key antes
          }
        }
      }
    })
    return newKeysArray;
  }, [fields])

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"lg"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear registro" : "Editar registro"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Loader open={isLoadingFileUpload} />
          <Grid container item xs={12} style={{ maxHeight: 500 }}>
            {
              fieldsKeyArrayWithBlankSpaces.map((fieldKey) => {
                return (
                  <Grid key={fieldKey} item xs={12} sm={6} style={gridStyle}>
                    {
                      !fields[fieldKey]
                        ? <></>
                        : getComponent(fields[fieldKey])
                    }
                  </Grid>
                )
              })
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {getDialogActions()}
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(CreateEditDialog);