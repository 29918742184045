import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { GalleryDialog } from "@icarius-common/galleryDialog";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { deleteImageAction, getGalleryImagesAction, uploadImageAction } from "@icarius-common/galleryDialog/actions";
import { getGalleryImages } from "@icarius-common/galleryDialog/selectors";
import { getAppColor } from "src/app/selectors";
import { ImagePlaceholder } from "@icarius-icons/index";
import useSetImageIndex from "@icarius-common/galleryDialog/components/useSetImageIndex";
import { withStyles } from "@material-ui/core/styles";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    data,
    codeList,
    accessTypeList,
    processList,
    userMenuList,
    detailTableList,
    create,
    modify,
    isAdmin,
    classes,
  } = props;

  const dispatch = useDispatch();
  const images = useSelector(getGalleryImages);
  const color = useSelector(getAppColor);

  const [imageIndex, setImageIndex] = useSetImageIndex(data, images);
  const [imagesDialogIsOpen, setImagesDialogIsOpen] = useState(false);

  useEffect(() => {
    dispatch(getGalleryImagesAction('news'));
  }, [dispatch])

  const uploadImage = (file) => {
    setImageIndex(null);
    dispatch(uploadImageAction(file, 'news'));
  }

  const deleteImage = (filename) => {
    setImageIndex(null);
    dispatch(deleteImageAction(filename, 'news'));
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openControlValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Si ingresa datos para control de integridad, es necesario que ingrese los 3 campos', severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, codeList, create, modify, openValidationError, openControlValidationError);

  const handleSelectImage = (index) => {
    setFormValue(index !== null ? images[index].img : "", "image");
    setImageIndex(index);
  }

  const handleWithCheckChange = () => {
    if (!codeList.find(item => item.key === formData.code)?.canUseCheck) {
      dispatch(openSnackbarAction({
        msg: 'Para indicar que la tabla tiene visado debe existir el campo “U_vis = Status de visación” dentro de la estructura de la tabla',
        severity: "error",
        duration: null
      }));
      return;
    }

    if ((!Boolean(formData.accessType) || formData.accessType === 'ME') && !formData.withCheck) {
      dispatch(openSnackbarAction({
        msg: 'Para indicar que la tabla tiene visado debe ingresar “Tipo de acceso”: Colaborador, Manager o Empleador.',
        severity: "error",
        duration: null
      }));
      return;
    }

    setFormValue(!Boolean(formData.withCheck), "withCheck");
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-code`}>{"Código de la tabla"}</InputLabel>
              <Select
                disabled={!isCreate}
                value={formData.code}
                labelId={`label-code`}
                id={`select-code`}
                onChange={(e) => setFormValue(e.target.value, "code")}
                margin={"none"}
              >
                {
                  codeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {`${item.value} (${item.key})`}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              required
              disabled={!isCreate}
              fullWidth
              margin={"none"}
              label={"Código de versión"}
              onChange={(e) => setFormValue(e.target.value, "codver")}
              value={formData.codver}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la tabla"}
              onChange={(e) => setFormValue(e.target.value, "name")}
              value={formData.name}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Grupo de la tabla"}
              onChange={(e) => setFormValue(e.target.value, "group")}
              value={formData.group}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-accessType`}>{"Tipo de acceso"}</InputLabel>
              <Select
                value={formData.accessType}
                labelId={`label-accessType`}
                id={`select-accessType`}
                onChange={(e) => setFormValue(e.target.value, "accessType")}
                margin={"none"}
              >
                {
                  accessTypeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-editorActive`}>{"Editor activo"}</InputLabel>
              <Select
                value={formData.editorActive}
                labelId={`label-editorActive`}
                id={`select-editorActive`}
                onChange={(e) => setFormValue(e.target.value, "editorActive")}
                margin={"none"}
              >
                {
                  [
                    { key: 'N', value: 'No' },
                    { key: 'Y', value: 'Si' },
                    { key: 'M', value: 'Solo para modificación' },
                    { key: 'V', value: 'Solo visualización' },
                  ].map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-importerActive`}>{"Importador activo"}</InputLabel>
              <Select
                value={formData.importerActive}
                labelId={`label-importerActive`}
                id={`select-importerActive`}
                onChange={(e) => setFormValue(e.target.value, "importerActive")}
                margin={"none"}
              >
                {
                  [{ key: 'N', value: 'No' }, { key: 'Y', value: 'Si' }].map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink id={`label-process`}>{"Proceso especial asociado"}</InputLabel>
              <Select
                value={formData.process}
                labelId={`label-process`}
                id={`select-process`}
                onChange={(e) => setFormValue(e.target.value, "process")}
                margin={"none"}
                displayEmpty
              >
                <MenuItem
                  className={"whiteText"}
                  value={''}>
                  {'Ninguno'}
                </MenuItem>
                {
                  processList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          {
            formData.accessType !== 'ME' && !formData.isDetailTable &&
            <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink id={`label-userMenu`}>{"Menú del usuario"}</InputLabel>
                <Select
                  value={formData.userMenu}
                  labelId={`label-userMenu`}
                  id={`select-userMenu`}
                  onChange={(e) => setFormValue(e.target.value, "userMenu")}
                  margin={"none"}
                  displayEmpty
                >
                  <MenuItem
                    className={"whiteText"}
                    value={''}>
                    {'Ninguno'}
                  </MenuItem>
                  {
                    userMenuList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              fullWidth
              margin={"none"}
              label={"Campos para filtro de datos en grilla"}
              onChange={(e) => setFormValue(e.target.value, "filterByFields")}
              value={formData.filterByFields}
              inputProps={{ maxLength: 254 }}
              helperText={"Por ej.: “U_cttipfun“ = ‘PROD’ and “U_ctcodof” IN('FA001','FA006')"}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, marginTop: 10 }}>
            <TextField
              fullWidth
              margin={"none"}
              label={"Campos para orden de datos en grilla"}
              onChange={(e) => setFormValue(e.target.value, "orderByFields")}
              value={formData.orderByFields}
              inputProps={{ maxLength: 254 }}
              helperText={"Campos separados por coma. Por ej.: \"U_codemp\",\"U_fecha\""}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <div
              style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }}
              onClick={handleWithCheckChange}
            >
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'Con visado'}
              </Typography>
              <Checkbox checked={formData.withCheck} />
            </div>
            <div
              style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 10 }}
              onClick={() => setFormValue(!formData.isDetailTable, 'isDetailTable')}
            >
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'Es tabla de detalle'}
              </Typography>
              <Checkbox checked={formData.isDetailTable} />
            </div>
            {
              !Boolean(formData.isDetailTable) &&
              <Grid container item alignItems="center" xs={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink id={`label-associatedDetailTable`}>{"Tabla de detalle asociada"}</InputLabel>
                  <Select
                    value={formData.associatedDetailTable}
                    labelId={`label-associatedDetailTable`}
                    id={`select-associatedDetailTable`}
                    onChange={(e) => setFormValue(e.target.value, "associatedDetailTable")}
                    margin={"none"}
                    displayEmpty
                  >
                    <MenuItem value={''}>
                      {'Ninguno'}
                    </MenuItem>
                    {
                      detailTableList?.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: 30, marginTop: 10 }}>
            <Typography className="whiteText">
              {"Para control de existencia durante la eliminación de registros"}
            </Typography>
          </Grid>
          <Grid container item alignItems="center" sm={12} md={4} style={gridStyle}>
            <TextField
              required={Boolean(formData.controlCode || formData.relatedTable || formData.relatedTableCode)}
              fullWidth
              margin={"none"}
              label={"Campo de control de la tabla"}
              onChange={(e) => setFormValue(e.target.value, "controlCode")}
              value={formData.controlCode}
              inputProps={{ maxLength: 18 }}
            />
          </Grid>
          <Grid container item alignItems="center" sm={12} md={4} style={gridStyle}>
            <TextField
              required={Boolean(formData.controlCode || formData.relatedTable || formData.relatedTableCode)}
              fullWidth
              margin={"none"}
              label={"Tabla relacionada"}
              onChange={(e) => setFormValue(e.target.value, "relatedTable")}
              value={formData.relatedTable}
              inputProps={{ maxLength: 18 }}
            />
          </Grid>
          <Grid container item alignItems="center" sm={12} md={4} style={gridStyle}>
            <TextField
              required={Boolean(formData.controlCode || formData.relatedTable || formData.relatedTableCode)}
              fullWidth
              margin={"none"}
              label={"Campo de la tabla relacionada"}
              onChange={(e) => setFormValue(e.target.value, "relatedTableCode")}
              value={formData.relatedTableCode}
              inputProps={{ maxLength: 18 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={{ padding: 10 }}>
            <TextField
              fullWidth
              label="SQL para ejecutar al momento de grabar"
              helperText={"La variable #CODE# contiene el valor del campo \"Code\" del registro insertado o modificado"}
              variant="outlined"
              multiline
              rows={10}
              margin={"none"}
              value={formData['sqlInsert']}
              onChange={(e) => setFormValue(e.target.value, "sqlInsert")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
          <Grid container item xs={12} style={{ width: 220, height: 140, display: "flex", alignItems: "center", justifyContent: "center" }}>
            {
              imageIndex !== null ?
                <img
                  style={{ maxWidth: 220, maxHeight: 140 }}
                  src={images && images.length > 0 ? IMAGES_ENDPOINT + images[imageIndex].img : ""}
                  alt={"imageNotFound"}
                />
                :
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 220, height: 140, backgroundColor: color }}>
                  <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                </div>
            }
          </Grid>
          <div style={{ marginTop: 10, width: "100%", display: 'flex', justifyContent: 'center' }} onClick={() => setImagesDialogIsOpen(true)}>
            <Typography variant="h6" className="whiteText" style={{ cursor: "pointer", fontWeight: 400, fontSize: 16, textDecoration: "underline" }}>
              {getLocalizedString("groupImagesTitle")}
            </Typography>
          </div>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ visibility: isAdmin ? 'visible' : 'hidden', paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        imagesDialogIsOpen &&
        <GalleryDialog
          imagePreselected={imageIndex}
          open={imagesDialogIsOpen}
          handleClose={() => setImagesDialogIsOpen(false)}
          handleSubmit={(img) => handleSelectImage(img)}
          handleUploadImage={uploadImage}
          handleDeleteUserImage={deleteImage}
        />
      }
    </>
  );
}

export default withStyles(styles)(Form);
