import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
    Typography,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const ConfirmationDialog = (props) => {

    const { open, handleConfirm, handleClose } = props;

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            maxWidth={"md"}>
            <DialogTitle>
                {'Atención'}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Typography className="whiteText" variant="h6" gutterBottom>
                    {'Hay modificaciones sin guardar, ¿Está seguro que desea salir?'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={'Quedarme'} />
                <ButtonDialogAction onClick={handleConfirm} isAccept text={'Salir'} />
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialog;
