import NAME from "./constants";

export const DELETE_REQUEST = NAME + "/DELETE_REQUEST";
export const DELETE_REQUEST_REJECTED = NAME + "/DELETE_REQUEST_REJECTED";
export const DELETE_REQUEST_FULFILLED = NAME + "/DELETE_REQUEST_FULFILLED";
export const DELETE_TOTEM_NOT_CREATED= NAME + "/DELETE_TOTEM_NOT_CREATED";
export const ADD_TOTEM_REJECTED = NAME + "/ADD_TOTEM_REJECTED";
export const ADD_TOTEM_FULFILLED = NAME + "/ADD_TOTEM_FULFILLED";
export const ADD_TOTEM = NAME + "/ADD_TOTEM";
export const GET_TOTEMS = NAME + "/GET_TOTEMS";
export const GET_TOTEMS_FULFILLED = NAME + "/GET_TOTEMS_FULFILLED";
export const GET_TOTEMS_REJECTED = NAME + "/GET_TOTEMS_REJECTED";
export const NEW_TOTEM = NAME + "/NEW_TOTEM";
export const UPDATE_TOTEM_REJECTED = NAME + "/UPDATE_TOTEM_REJECTED";
export const UPDATE_TOTEM_FULFILLED = NAME + "/UPDATE_TOTEM_FULFILLED";
export const UPDATE_TOTEM = NAME + "/UPDATE_TOTEM";