import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AddRowStatusBar from '@icarius-common/editableGrid/Components/addRow/addRowButton';
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getTaxTableAction, deleteTaxTableAction, setTaxTableAction } from "../actions";
import { getRows, getLoading } from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import { openDialogAction } from "@icarius-common/dialog/actions";

const TaxTable = ({ history }) => {

  const [dataChanged, setDataChanged] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);

  const dispatch = useDispatch();
  const rowData = useSelector(getRows);
  const isLoading = useSelector(getLoading);

  const gridRef = useRef();

  useEffect(() => {
    dispatch(getTaxTableAction());
    window.scrollTo(0, 0);
  }, [dispatch])

  useEffect(() => {
    if (gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef])

  const handleSave = () => {
    const rowData = gridReference.getVisibleRows().map(item => item.data);

    const formattedData = rowData.map(row => {

      return {
        rate: row.rate,
        to: row.to,
        reduction: row.reduction,
        factor: row.factor,
      }
    })

    dispatch(setTaxTableAction(formattedData))
      .then((resp) => {
        //limpiar el seleccionado
        gridReference.deselectAll()

        if (resp?.response?.data?.status === 'DUPLICATED_DATA') {
          const rowsToSelect = [];
          const keysToSelect = resp.response.data.codes;

          gridReference.getVisibleRows().forEach(node => {
            if (keysToSelect.includes(node.data.rate)) {
              rowsToSelect.push(node.key);
            }
          });

          gridReference.selectRows(rowsToSelect);
        } else {
          setShouldReloadGrid(true);
        }

        if (resp?.status === 200) {
          setDataChanged(false);
        }
      })
  };

  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsFromServer(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Valores eliminados con éxito', severity: "success" }));
  }

  const deleteRowsFromServer = (rowsToDelete) => {
    const codesToSend = rowsToDelete.filter(row => !row.hasOwnProperty("__KEY__")).map(row => row.rate);

    if (codesToSend.length > 0) {
      dispatch(deleteTaxTableAction(codesToSend))
        .then((resp) => {
          //limpiar el seleccionado
          gridReference.deselectAll();

          if (resp?.response?.data?.status === 'IN_USE') {
            const keysToSelect = resp.response.data.dependants;
            const rowsToSelect = [];
            gridReference.getVisibleRows().forEach(node => {
              if (keysToSelect.includes(node.data.rate)) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          } else {
            deleteRowsLocally(rowsToDelete);
          }
        })
    } else if (rowsToDelete.length > codesToSend.length) {
      deleteRowsLocally(rowsToDelete);
    }
  }

  const handleGoBack = () => {
    history.push(paths.ratingsAndGeneralValues);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      handleGoBack();
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Tabla de impuestos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'num' && gridCell.value !== "") {
              excelCell.value = parseFloat(gridCell.value);
              excelCell.numFmt = '_(#,##0.000_);_((#,##0.000)';
            }
          }
        }
      },
    })
      .then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Tabla de impuestos.xlsx');
        });
      });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const addRowButton = () => (
    <AddRowStatusBar {...gridRef.current} />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])

  return (
    <CommonPage
      title={'Tabla de impuestos'}
      menuItems={[goBackButton, addRowButton, saveItem, exportGrid, deleteItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <EditableGridv2
        setShouldReloadGrid={setShouldReloadGrid}
        shouldReloadGrid={shouldReloadGrid}
        setDataChanged={setDataChanged}
        ref={gridRef}
        gridName="taxTable"
        rowData={rowData}
        columns={getColumnDefByPage(paths.taxTable)}
      />
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default TaxTable;
