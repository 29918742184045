import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  ContractorIcon,
  ThirdPartyClassificationIcon,
  ThirdPartyIcon,
} from "@icarius-icons/";

const ThirdPartySettings = () => {

  const data = [
    {
      name: "Contratistas",
      icon: <ContractorIcon />,
      path: paths.contractorSettings,
    },
    {
      name: "Clasificación de terceros",
      icon: <ThirdPartyClassificationIcon />,
      path: paths.thirdPartyClassification,
    },
    {
      name: "Terceros",
      icon: <ThirdPartyIcon />,
      path: paths.thirdParty,
    },
  ];
  return (
    <ScreenSelector
      title={getLocalizedString("thirdPartySettings")}
      data={data}
    />
  );
}

export default ThirdPartySettings;
