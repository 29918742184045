import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Código de campo',
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Nombre del campo',
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Tipo de campo',
            field: "typeString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Tamaño',
            field: "size",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Tipo de ingreso',
            field: "requiredString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Comentario del campo',
            field: "comment",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Componente visual para editor',
            field: "componentString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Orden',
            field: "order",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Campo clave',
            field: "keyFieldString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Oculto',
            field: "hiddenString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Protegido',
            field: "disabledString",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.userTablesFieldsDefinition, paths.systemTablesFieldsDefinition],
        config: {
            headerName: 'Tipo de columna en grilla',
            field: "columnTypeInGridString",
            filter: "agSetColumnFilter",
        }
    },
]