import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  OnboardingProfilesIcon,
  ClassificationApplicantsTypesIcon,
  TypesOfPublicationFormatsIcon,
  TypesOfPublicationChannelsIcon,
  TypesOfOnboardingActionsIcon,
  OnboardingMaterialsIcon,
} from "@icarius-icons/";
import paths from "@icarius-localization/paths";

const Index = () => {

  const data = [
    {
      name: "Tipos de clasificación para postulantes",
      icon: <ClassificationApplicantsTypesIcon />,
      path: paths.classificationApplicantsTypes,
    },
    {
      name: "Tipos de canales para las publicaciones",
      icon: <TypesOfPublicationChannelsIcon />,
      path: paths.typesOfPublicationChannels,
    },
    {
      name: "Tipos de formatos para las publicaciones",
      icon: <TypesOfPublicationFormatsIcon />,
      path: paths.typesOfPublicationFormats,
    },
    {
      name: "Tipos de acciones de onboarding",
      icon: <TypesOfOnboardingActionsIcon />,
      path: paths.typesOfOnboardingActions,
    },
    {
      name: "Materiales y recursos de onboarding",
      icon: <OnboardingMaterialsIcon />,
      path: paths.onboardingMaterials,
    },
    {
      name: "Perfiles de etapas de onboarding",
      icon: <OnboardingProfilesIcon />,
      path: paths.onboardingProfiles,
    },
  ];

  return (
    <ScreenSelector
      title={"Definiciones de adquisición del talento"}
      data={data}
    />
  );
}

export default Index;
