import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getTotemsFromServer,
  deleteTotemAction,
  newTotem,
  addTotem,
  updateTotemAction,
  deleteTotemNotCreated,
} from "../actions";
import {
  getTotems,
  getWorkplaces,
  loading,
} from "../selectors";
import { getAppColor } from "../../../app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getSocietySelected } from "../../login/selectors";
import DeleteDialog from "./dialogs/deleteDialog";
import { isKeyPresent, getValueFromKey, deleteKey } from "@icarius-utils/localStorage";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import TotemItem from "./totem/TotemItem";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";

const TotemManagement = () => {

  const [selectedTotem, setSelectedTotem] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const workplaces = useSelector(getWorkplaces);
  const availableTotems = useSelector(getTotems);
  const societySelected = useSelector(getSocietySelected);
  const loadingRows = useSelector(loading);
  const color = useSelector(getAppColor);

  useEffect(() => {
    dispatch(getTotemsFromServer());
  }, [dispatch])

  const handleDeleteTotem = (data, isEdit, index) => {
    let selectedTotem = data;
    if (isEdit && index > -1) {
      selectedTotem = { ...data, isEdit: isEdit, index: index };
    }
    setSelectedTotem(selectedTotem);
    setIsDeleteDialogOpen(true);
  }

  const handleTotem = (data, isUpdate) => {
    const selectedWorkplace = workplaces.find(workplace => workplace.id === data.workplace);

    let dataWithWorkplaceName = { ...data };
    dataWithWorkplaceName.workplaceName = selectedWorkplace.value;
    dataWithWorkplaceName.societyName = societySelected.name;

    const callback = isUpdate ? updateTotemAction : addTotem;

    dispatch(callback(dataWithWorkplaceName));
  }

  const handleClickNewTotem = () => {
    dispatch(newTotem());
  }

  const deleteTotem = (data) => {
    if (data.isEdit) {
      dispatch(deleteTotemAction(data))
        .then(resp => {
          if (resp.data.status === "OK") {
            setSelectedTotem(null);
            setIsDeleteDialogOpen(false);
          }
        });
    } else {
      dispatch(deleteTotemNotCreated(data.index));
      setSelectedTotem(null);
      setIsDeleteDialogOpen(false);
    }
  }

  const handleDeleteTotemClose = () => {
    setIsDeleteDialogOpen(false);
    setSelectedTotem(null);
  }

  // Debo buscar si el array tiene el totem registrado en este dispositivo
  // Caso contrario, es porque alguien lo borro, entonces ya no sirve!
  if (!loading && availableTotems && isKeyPresent("assistance-totem-id")) {
    const localStorageTotemID = getValueFromKey("assistance-totem-id");
    const existeTotem = availableTotems.find(totem => totem.code === localStorageTotemID);

    if (!existeTotem) {
      deleteKey("assistance-totem-id");
      deleteKey("assistance-totem-workplace");
      deleteKey("assistance-totem-society-name");
      deleteKey("assistance-totem-timezone");
    }
  }

  const getDataIsEmpty = () => {
    return !availableTotems || availableTotems.length === 0;
  }

  return (
    <ABMLayout
      title={getLocalizedString("totemManagementTitle")}
      isLoading={loadingRows}
      showCreateButton={!isKeyPresent("assistance-totem-id")}
      createButtonTitle={getLocalizedString("newTotem")}
      createButtonOnClick={handleClickNewTotem}
      showNoData={getDataIsEmpty()}
      noDataTitle={getLocalizedString("noTotems")}
      noDataCreateTitle={getLocalizedString("newTotem")}
      noDataOnClick={handleClickNewTotem}
      showGoBack
      handleGoBack={() => history.push(paths.timeDefinition)}
    >
      {
        availableTotems.map((totem, index) =>
          <TotemItem
            key={totem.code}
            index={index}
            color={color}
            isEdit={totem.code ? true : false}
            data={totem}
            addTotem={(data) => handleTotem(data, false)}
            updateTotem={(data) => handleTotem(data, true)}
            deleteHandle={handleDeleteTotem}
            workplaces={workplaces}
          />
        )
      }
      {
        isDeleteDialogOpen &&
        <DeleteDialog
          totem={selectedTotem}
          open={isDeleteDialogOpen}
          handleClose={handleDeleteTotemClose}
          handleConfirm={deleteTotem}
        />
      }
    </ABMLayout>
  )
}

export default TotemManagement;