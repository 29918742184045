import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import useHandleFormDuplicate from "./useHandleFormDuplicate";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { duplicateTableDefinitionAction } from "../../actions";
import { getLoading } from "../../selectors";

const gridStyle = { height: 75, padding: "10px" };

const DuplicateDialog = (props) => {

  const { data, open, codeList, isSystemTables, handleClose } = props;

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);

  const handleDuplicate = (dataToSend) => {
    dispatch(duplicateTableDefinitionAction(isSystemTables, dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, handleDuplicate, openValidationError);

  if (isLoading) return null;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Duplicar definición de tabla: ${data.name}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container item xs={12}>
          <Typography className="whiteText" style={{ padding: "10px" }}>
            {`Versión: ${data.codver}`}
          </Typography>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-code`}>{"Código de tabla destino"}</InputLabel>
              <Select
                value={formData.code}
                labelId={`label-code`}
                id={`select-code`}
                onChange={(e) => setFormValue(e.target.value, "code")}
                margin={"none"}
              >
                {
                  codeList.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la tabla destino"}
              onChange={(e) => setFormValue(e.target.value, "name")}
              value={formData.name}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Código de versión para tabla destino"}
              onChange={(e) => setFormValue(e.target.value, "codver")}
              value={formData.codver}
              inputProps={{ maxLength: 30 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
