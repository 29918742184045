import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import {
  getTypeList,
  getComponentList,
  getGridTypeList,
  getRequiredList,
  getFieldList,
  getDisabledList,
} from "../../selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const CreateEditDialog = (props) => {

  const {
    open,
    tableCode,
    codver,
    isLoading,
    data,
    rows,
    handleClose,
    create,
    modify,
    classes,
  } = props;

  const typeList = useSelector(getTypeList);
  const componentList = useSelector(getComponentList);
  const gridTypeList = useSelector(getGridTypeList);
  const requiredList = useSelector(getRequiredList);
  const fieldList = useSelector(getFieldList);
  const disabledList = useSelector(getDisabledList);

  const dispatch = useDispatch();

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, tableCode, codver, fieldList, create, modify, openValidationError);

  const codesInUse = rows.map((item) => item.code);
  const codeOptionList = fieldList.filter((item) => !codesInUse.includes(item.key));
  const filterFieldOptionList = rows.filter((item) => item.component === "L").map((item) => ({ key: item.code, value: item.name }));

  const fieldHasRelatedTable = Boolean(fieldList.find(item => item.key === formData.code)?.hasRelatedTable);
  const fieldHasRelatedOptionList = Boolean(fieldList.find(item => item.key === formData.code)?.hasRelatedOptionList);

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {isCreate ? "Crear definición de campo" : "Editar definición de campo"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: 0 }}>
          <Grid container item xs={12}>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-code`}>{"Código de campo"}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData.code}
                  labelId={`label-code`}
                  id={`select-code`}
                  onChange={(e) => setFormValue(e.target.value, "code")}
                  margin={"none"}
                >
                  {
                    (isCreate ? codeOptionList : fieldList) // si no es create va la lista sin filtrar para que salga la opción ya seteada
                      ?.map(item => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {`${item.value} (${item.key})`}
                        </MenuItem>
                      ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                fullWidth
                margin={"none"}
                label={"Nombre de campo"}
                onChange={(e) => setFormValue(e.target.value, "name")}
                value={formData.name}
                inputProps={{ maxLength: 30 }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-type`}>{"Tipo de campo"}</InputLabel>
                <Select
                  value={formData.type}
                  labelId={`label-type`}
                  id={`select-type`}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    typeList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-required`}>{"Tipo de ingreso"}</InputLabel>
                <Select
                  value={formData.required}
                  labelId={`label-required`}
                  id={`select-required`}
                  onChange={(e) => setFormValue(e.target.value, "required")}
                  margin={"none"}
                >
                  {
                    requiredList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                style={{ marginTop: 14 }}
                fullWidth
                margin={"none"}
                label={"Comentario del campo"}
                onChange={(e) => setFormValue(e.target.value, "comment")}
                value={formData.comment}
                inputProps={{ maxLength: 40 }}
                helperText={`Cant. caracteres: ${formData.comment.length}. Max. permitido: 40.`}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <TextField
                required
                type={'number'}
                fullWidth
                margin={"none"}
                label={"Orden"}
                onChange={(e) => setFormValue(e.target.value, "order")}
                value={formData.order}
                inputProps={{ min: "1", step: "1", max: 99 }}
                onBlur={e => {
                  if (e.target.value !== "") {
                    e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(0);
                    setFormValue(e.target.value, 'order');
                  }
                }}
                onInput={e => {
                  setFormValue(e.target.value, 'order');
                }}
              />
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-component`}>{"Componente visual para editor"}</InputLabel>
                <Select
                  value={formData.component}
                  labelId={`label-component`}
                  id={`select-component`}
                  onChange={(e) => setFormValue(e.target.value, "component")}
                  margin={"none"}
                >
                  {
                    (componentList[formData.type] || []).map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-hidden`}>{"Oculto"}</InputLabel>
                <Select
                  value={formData.hidden}
                  labelId={`label-hidden`}
                  id={`select-hidden`}
                  onChange={(e) => setFormValue(e.target.value, "hidden")}
                  margin={"none"}
                >
                  {
                    [{ key: 'N', value: 'No' }, { key: 'Y', value: 'Si' }].map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-keyField`}>{"Control de repetición"}</InputLabel>
                <Select
                  value={formData.keyField}
                  labelId={`label-keyField`}
                  id={`select-keyField`}
                  onChange={(e) => setFormValue(e.target.value, "keyField")}
                  margin={"none"}
                >
                  {
                    [{ key: 'N', value: 'No' }, { key: 'Y', value: 'Si' }].map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-disabled`}>{"Protegido"}</InputLabel>
                <Select
                  value={formData.disabled}
                  labelId={`label-disabled`}
                  id={`select-disabled`}
                  onChange={(e) => setFormValue(e.target.value, "disabled")}
                  margin={"none"}
                >
                  {
                    disabledList.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel required id={`label-columnTypeInGrid`}>{"Tipo de columna en grilla"}</InputLabel>
                <Select
                  value={formData.columnTypeInGrid}
                  labelId={`label-columnTypeInGrid`}
                  id={`select-columnTypeInGrid`}
                  onChange={(e) => setFormValue(e.target.value, "columnTypeInGrid")}
                  margin={"none"}
                >
                  {
                    gridTypeList?.map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            {
              formData.component === 'L' &&
              <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}>
                {
                  fieldHasRelatedTable &&
                  <Typography className="whiteText" style={{ margin: 10, marginBottom: 20, marginTop: 0 }}>
                    {'Este campo tiene una relación automática entre tablas. Por ende, la lista desplegable se cargará en forma automática excepto que usted ingrese su propio SQL para la lista.'}
                  </Typography>
                }
                {
                  fieldHasRelatedOptionList &&
                  <Typography className="whiteText" style={{ margin: 10, marginBottom: 20, marginTop: 0 }}>
                    {'Este campo tiene una lista de valores válidos asociados. Por ende, la lista desplegable se cargará en forma automática excepto que usted ingrese su propio SQL para la lista.'}
                  </Typography>
                }
                <TextField
                  required={!fieldHasRelatedTable && !fieldHasRelatedOptionList}
                  fullWidth
                  label="SQL para lista desplegable"
                  variant="outlined"
                  multiline
                  rows={10}
                  margin={"none"}
                  value={formData.sql}
                  onChange={(e) => setFormValue(e.target.value, "sql")}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.cssOutlinedInput,
                      focused: classes.cssFocused,
                      notchedOutline: classes.notchedOutline,
                    }
                  }}
                  inputProps={{ spellCheck: 'false' }}
                  helperText={
                    <>
                      Para el SQL es necesario que el primer campo del SELECT se llame "Code", el cual debe contener el código del desplegable, y el segundo campo se llame "Name", el cual debe contener la descripción del desplegable.
                      <br />
                      Si utiliza filtro, incluya el campo para filtrar como tercer campo de la consulta.
                    </>
                  }
                />
              </Grid>
            }
            {
              formData.type === 'A' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <TextField
                  required
                  type={'number'}
                  fullWidth
                  margin={"none"}
                  label={"Tamaño"}
                  onChange={(e) => setFormValue(e.target.value, "size")}
                  value={formData.size}
                  inputProps={{ min: "1", step: "1" }}
                  onBlur={e => {
                    if (e.target.value !== "") {
                      e.target.value = (Math.round(e.target.value * 100) / 100).toFixed(0);
                      setFormValue(e.target.value, 'size');
                    }
                  }}
                  onInput={e => {
                    setFormValue(e.target.value, 'size');
                  }}
                />
              </Grid>
            }
            {
              formData.component === 'L' &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                <FormControl style={{ width: "100%" }}>
                  <InputLabel shrink id={`label-filterField`}>{"Campo para filtro"}</InputLabel>
                  <Select
                    value={formData.filterField}
                    labelId={`label-filterField`}
                    id={`select-kfilterFieldeyField`}
                    onChange={(e) => setFormValue(e.target.value, "filterField")}
                    margin={"none"}
                    displayEmpty
                  >
                    {
                      [
                        { key: "", value: "Ninguno" },
                        ...(filterFieldOptionList || [])
                      ].map((item) => (
                        <MenuItem
                          className={"whiteText"}
                          key={item.key}
                          value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
            }
          </Grid>
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default withStyles(styles)(CreateEditDialog);