import React from "react";
import paths from "@icarius-localization/paths";
import ScreenSelector from "@icarius-common/screenSelector";
import {
  TurnDefinitionIcon,
  ScheduleDefinitionIcon,
  SchedulesProgrammingIcon,
  CalendarDefinitionIcon,
  ExtraHoursTableIcon,
  TotemsIcon,
} from "@icarius-icons/";

const TimeDefinition = () => {

  const data = [
    {
      name: "Definición de calendarios",
      icon: <CalendarDefinitionIcon />,
      path: paths.calendarDefinition,
    },
    {
      name: "Definición de turnos",
      icon: <TurnDefinitionIcon />,
      path: paths.turnDefinition,
    },
    {
      name: "Definición de horarios",
      icon: <ScheduleDefinitionIcon />,
      path: paths.schedulesDefinition,
    },
    {
      name: "Programación de horarios",
      icon: <SchedulesProgrammingIcon />,
      path: paths.schedulesProgramming,
    },
    {
      name: "Definición de tipos de horas extras",
      icon: <ExtraHoursTableIcon />,
      path: paths.extraHoursTable,
    },
    {
      name: "Gestión de totems",
      icon: <TotemsIcon />,
      path: paths.totems,
    },
  ];

  return (
    <ScreenSelector
      title={"Definición de tiempos"}
      data={data}
    />
  );
}

export default TimeDefinition;