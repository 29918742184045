import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { executeTableFormAction } from "../actions";

const useProcessExecution = (isSystemTables, process, addCodemps) => {

  const dispatch = useDispatch();

  const [paramsToUse, setParamsToUse] = useState(null);
  const [paramDialogIsOpen, setParamDialogIsOpen] = useState(false);
  const [selectedCodes, setSelectedCodes] = useState(null);
  const [selectedCodemps, setSelectedCodemps] = useState(null);

  const handleValidateAndAskProcess = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const codes = selectedRows.map(item => item.internalCode);
    const codemps = addCodemps ? selectedRows.map(item => item.codemp) : null;

    setSelectedCodes(codes);
    setSelectedCodemps(codemps);

    dispatch(openDialogAction({
      title: "Atención",
      msg: `¿Está seguro que desea ejecutar el proceso: ${process.value}?`,
      onConfirm: () => handleStartExecutionProcess(codes, codemps),
      onCancel: () => handleCloseParamDialog(),
    }));
  }

  const handleStartExecutionProcess = (codes, codemps) => {
    if (process?.parameters?.length) {
      //abrir modal de setparam
      setParamDialogIsOpen(true);
      setParamsToUse(process.parameters);
      return;
    }

    // si no tiene parametros
    executeProcess(null, codes, codemps);
  }

  const handleCloseParamDialog = () => {
    setParamDialogIsOpen(false);
    setParamsToUse(null);
    setSelectedCodes(null);
    setSelectedCodemps(null);
  }

  const executeProcess = (param, codes, codemps) => {
    dispatch(executeTableFormAction(
      isSystemTables,
      {
        codes: selectedCodes || codes,
        codemps: selectedCodemps || codemps,
        process: process.key,
        parameters: param || [],
      }
    ))
      .then(() => {
        handleCloseParamDialog();
      })
  }

  const processExecutionData = {
    paramsToUse,
    paramDialogIsOpen,
  }

  const processExecutionFunctions = {
    handleValidateAndAskProcess,
    executeProcess,
    handleCloseParamDialog,
  }

  return {
    processExecutionData,
    processExecutionFunctions,
  }
}

export default useProcessExecution;
