import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  loading: false,  
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROWS:
      return { ...state, loading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,  
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, loading: false };    
    case actionTypes.SET_ROWS:
      return { ...state, loading: true };
    case actionTypes.SET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: action.payload.rows,  
      };
    case actionTypes.SET_ROWS_REJECTED:
      return { ...state, loading: false };    
    case actionTypes.DELETE_ROWS:
      return { ...state, loading: true };
    case actionTypes.DELETE_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        // rows: action.payload.rows,  
      };
    case actionTypes.DELETE_ROWS_REJECTED:
      return { ...state, loading: false };    
    default:
      return state;
  }
}
