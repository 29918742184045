import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThirdPartyClassificationAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";

const ThirdPartyClassification = ({ history }) => {

  const [ createIsOpen, setCreateIsOpen ] = useState(false);

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getThirdPartyClassificationAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.thirdPartySettings);
  }

  return (
    <ABMLayout
      title={'Clasificación de terceros'}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={'Nueva clasificación de terceros'}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={'No se encontraron clasificaciones de terceros'}
      noDataCreateTitle={'Nueva clasificación de terceros'}
      noDataOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}            
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        data && data.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem data={item} color={color} />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default ThirdPartyClassification;