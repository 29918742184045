import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Grid,
  Card,
  Tooltip,
} from "@material-ui/core";
import {
  getLocalizedErrorString,
  getLocalizedString,
} from "@icarius-localization/strings";
import {
  DeleteIcon,
  FileCopyIcon,
  GetAppIcon,
  FilterIcon,
} from "@icarius-icons";
import Form from "./form";
import {
  createTableDefinitionAction,
  deleteTableDefinitionAction,
  exportTableDefinitionAction,
  updateTableDefinitionAction,
  getTablesDefinitionAction
} from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import DuplicateDialog from "../duplicateDialog/index";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { exportIcariusFile } from "@icarius-utils/exportImportIcarius";
import { FiltersDialog } from "@icarius-common/filtersDialog";
import useFilterDialog from "@icarius-common/filtersDialog/components/useFilterDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const {
    data,
    codeList,
    accessTypeList,
    processList,
    userMenuList,
    detailTableList,
    color,
    closeNew,
    handleOpenTableFieldsPage,
    isAdmin,
    isSystemTables,
    filtersIsLoading,
  } = props;

  const { filtersDialogIsOpen, setFiltersDialogIsOpen } = useFilterDialog();

  const dispatch = useDispatch();
  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);

  const create = (dataToSend) => {
    dispatch(createTableDefinitionAction(isSystemTables, dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          isCreate ? closeNew() : handleClosePublication();
        }
      });
  }

  const modify = (dataToSend) => {
    dispatch(updateTableDefinitionAction(isSystemTables, dataToSend))
      .then((res) => {
        if (res?.status === 200) {
          isCreate ? closeNew() : handleClosePublication();
        }
      });
  }

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteTableDefinitionAction(isSystemTables, data));
    }
  }

  const handleExport = () => {
    const dataToSend = {
      code: data.code,
      codver: data.codver,
    };

    dispatch(exportTableDefinitionAction(isSystemTables, dataToSend))
      .then(resp => {
        if (resp.data.status === "OK") {
          exportIcariusFile(dispatch, resp.data.result, `${data.code} - ${data.codver}`);
        } else {
          dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
        }
      });
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const accessTypeString = accessTypeList?.find(item => item.key === data?.accessType)?.value || '';

  const getEditorActiveLabel = (editorActive) => {
    switch (editorActive) {
      case "Y":
        return "Editor activo";
      case "N":
        return "Editor inactivo";
      case "M":
        return "Editor solo para modificación";
      case "V":
        return "Solo visualización";
      default:
        return "";
    }
  }

  const getHasFilters = () => {
    return Object.keys(data.filterData).some((filterName) => !data.filterData[filterName].includes("-"));
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: `Eliminar tabla del ${isSystemTables ? 'sistema' : 'usuario'}`,
      msg: `¿Desea eliminar la tabla del ${isSystemTables ? 'sistema' : 'usuario'}?`,
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Tooltip title={`Versión: ${data?.codver || ''}`}>
        <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
          <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
            <Grid container item xs={10} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
              {
                isCreate ?
                  <DataContainer lg={12} sm={12} md={12} text={`Creación de tabla del ${isSystemTables ? 'sistema' : 'usuario'}`} />
                  :
                  <>
                    <DataContainer sm={12} md={6} lg={3} text={data.name} />
                    <DataContainer sm={12} md={6} lg={3} text={data.code} />
                    <DataContainer sm={12} md={6} lg={3} text={accessTypeString} />
                    <DataContainer sm={12} md={6} lg={3} text={getEditorActiveLabel(data.editorActive)} />
                  </>
              }
            </Grid>
            <Grid container direction="row" item xs={2} justify={isCreate ? "flex-end" : "space-between"}>
              {
                !isCreate && isAdmin &&
                <>
                  <Tooltip title={'Filtrar'}>
                    <IconButton size={"small"} disableRipple onClick={() => setFiltersDialogIsOpen(true)}>
                      <FilterIcon style={{ fill: getHasFilters() ? color : "var(--icons)", height: "15px" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Exportar'}>
                    <IconButton size={"small"} disableRipple onClick={handleExport}>
                      <GetAppIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Estructura de campos'}>
                    <IconButton size={"small"} disableRipple onClick={() => handleOpenTableFieldsPage(data)}>
                      <OpenInNewIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={getLocalizedString("duplicate")}>
                    <IconButton size={"small"} disableRipple onClick={() => setDuplicateDialogIsOpen(true)}>
                      <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </>
              }
              {
                isAdmin &&
                <Tooltip title={getLocalizedString("delete")}>
                  <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                    <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                  </IconButton>
                </Tooltip>
              }
            </Grid>
          </Grid>
        </Card>
      </Tooltip>
      {
        isOpen &&
        <Form
          data={data}
          codeList={codeList}
          accessTypeList={accessTypeList}
          processList={processList}
          userMenuList={userMenuList}
          detailTableList={detailTableList}
          create={create}
          modify={modify}
          isAdmin={isAdmin}
        />
      }
      {
        duplicateDialogIsOpen &&
        <DuplicateDialog
          open={duplicateDialogIsOpen}
          data={data}
          codeList={codeList}
          isSystemTables={isSystemTables}
          handleClose={() => setDuplicateDialogIsOpen(false)}
        />
      }
      {
        filtersDialogIsOpen && !filtersIsLoading &&
        <FiltersDialog
          open={filtersDialogIsOpen}
          type={isSystemTables ? 'systemTables' : 'userTables'}
          code={data.internalCode}
          data={data.filterData}
          handleFetchUpdatedData={() => dispatch(getTablesDefinitionAction(isSystemTables))}
          handleClose={() => setFiltersDialogIsOpen(false)}
        />
      }
    </>
  );
}

export default DataItem;
