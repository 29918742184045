import React, { useState } from "react";
import SystemAndUserTablesDefinition from "./systemAndUserTablesDefinition";
import { SystemAndUserTablesFieldsDefinition } from "@icarius-pages/systemAndUserTablesFieldsDefinition";
import paths from "@icarius-localization/paths";

const SystemAndUserTablesDefinitionContainer = ({ location }) => {

  const isSystemTables = location.pathname === paths.systemTablesDefinition;
  const [tableData, setTableData] = useState(null);

  if (tableData) {
    return (
      <SystemAndUserTablesFieldsDefinition
        handleGoBack={() => setTableData(null)}
        tableData={tableData}
        isSystemTables={isSystemTables}
      />
    )
  }

  return (
    <SystemAndUserTablesDefinition
      handleOpenTableFieldsPage={(data) => setTableData(data)}
      isSystemTables={isSystemTables}
    />
  )
}

export default SystemAndUserTablesDefinitionContainer;