import * as actionTypes from "./actionTypes";

const initialState = {
  data: null,
  codeList: [],
  accessTypeList: [],
  processList: [],
  detailTableList: [],
  userMenuList: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        codeList: action.payload.codeList,
        accessTypeList: action.payload.accessTypeList,
        processList: action.payload.processList,
        detailTableList: action.payload.detailTableList,
        userMenuList: action.payload.userMenuList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_FULFILLED:
      return { ...state, isLoading: false, data: action.payload.data };
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.IMPORT_EXPORT:
      return { ...state, isLoading: true };
    case actionTypes.IMPORT_EXPORT_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.IMPORT_EXPORT_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
