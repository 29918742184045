const NAME = "SYSTEM_AND_USER_TABLES";

export const dataTypes = {
    alfanumeric: "A",
    multiline: "M",
    date: "D",
    time: "T",
    period: "P",
    integer: "N",
    twoDecimals: "B",
    threeDecimals: "Q",
    fourDecimals: "R",
    fiveDecimals: "S",
    boolean: "C",
}

export const componentTypes = {
    text: "E",
    list: "L",
    multiline: "M",
    dateWithPicker: "D",
    dateNoPicker: "DS",
    timeWithPicker: "T",
    timeNoPicker: "TS",
    periodWithPicker: "P",
    periodNoPicker: "PS",
    integer: "N",
    twoDecimals: "B",
    threeDecimals: "Q",
    fourDecimals: "R",
    fiveDecimals: "S",
    checkbox: "C",
    link: "LK",
    imageLink: "LI",
    imageInput: "IU",
    fileLink: "LF",
    fileInput: "AU",
    geocoordinates: "GC",
}

export default NAME;
