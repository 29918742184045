import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import { Typography, DialogContent, DialogActions, DialogTitle, Dialog } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const DeleteDialog = ({ open, totem, handleClose, handleConfirm }) => {
  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"xs"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("atention")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Typography className="whiteText" variant="h6" gutterBottom>
        {getLocalizedString("promptDeleteTotem")}
        </Typography>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction
          onClick={handleClose}
          text={getLocalizedString("disagree")}
        />
        <ButtonDialogAction
          onClick={() => handleConfirm(totem)}
          isAccept
          text={getLocalizedString("agree")}
        />
      </DialogActions>
    </Dialog>
  );
}

export default DeleteDialog;
