import { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { openDialogAction } from "@icarius-common/dialog/actions";
import {
  getIsLoading,
  getGridData,
  getFormData,
  getFields,
  getProcess,
  getDateFormat,
  getAddCodemps,
} from "../selectors";
import {
  createTableFormAction,
  updateTableFormAction,
  deleteTableFormAction,
  downloadTableExcelAction,
  changeCheckTableFormAction,
} from "../actions";
import { createAggridColumn } from "@icarius-table/utils";
import {
  numberFormatterMin0Max0,
  numberFormatterMin2Max2,
  numberFormatterMin3Max3,
  numberFormatterMin4Max4,
  numberFormatterMin5Max5,
} from "@icarius-table/number";
import { getUserRole } from "src/app/selectors";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";

const useSystemTablesForm = (isSystemTables, tableCode, codver, detailTable, masterRowInternalCode) => {

  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const level = useSelector(getUserRole);
  const addCodemps = useSelector(getAddCodemps);
  const gridData = useSelector(getGridData);
  const formData = useSelector(getFormData);
  const fields = useSelector(getFields);
  const isLoading = useSelector(getIsLoading);
  const process = useSelector(getProcess);
  const dateFormat = useSelector(getDateFormat);

  const handleOpenDeleteDialog = (gridRef) => {
    let rows = gridRef.api.getSelectedRows();
    if (rows.length < 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const hasCheck = Object.hasOwn(rows[0], 'Status de visación');

    if ((level === 'C' || level === 'M') && hasCheck) {
      if (!rows.every((item) => item['Status de visación'] === 'Ingresado')) {
        dispatch(openSnackbarAction({ msg: 'Solo es posible eliminar transacciones cuyo estado sea Ingresada', severity: "warning" }));
        return;
      }
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: `¿Desea eliminar los registros seleccionados?`,
      onConfirm: () => handleDelete(rows),
    }));
  }

  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setCreateDialogIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let rows = gridRef.api.getSelectedRows();
    if (rows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(formData.find((item) => item.internalCode === rows[0].internalCode));
    setEditDialogIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setCreateDialogIsOpen(false);
    setEditDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleDelete = (rows) => {
    const codes = rows.map((item) => item.internalCode);
    dispatch(deleteTableFormAction(isSystemTables, { tableCode, codver, codes, masterRowInternalCode }));
  }

  const hasAggridColumnWithRenderer = useMemo(() => {
    const fieldsWithRenderer = Object.values(fields)?.filter((fieldItem) => ["LK", "LI", "LF", "GC", "IU", "AU"].includes(fieldItem.columnTypeInGrid));

    return Boolean(fieldsWithRenderer?.length > 0);
  }, [fields])

  const columnDefs = useMemo(() => {
    let columnDefs = [
      {
        headerName: 'Código interno',
        field: 'internalCode',
        filter: "agTextColumnFilter",
        checkboxSelection: function (params) {
          return params.columnApi.getRowGroupColumns().length === 0;
        },
        headerCheckboxSelection: function (params) {
          return params.columnApi.getRowGroupColumns().length === 0;
        },
        headerCheckboxSelectionFilteredOnly: true,
        enableRowGroup: true,
        maxWidth: 250,
      },
    ];

    if (Object.keys(fields)?.length > 0) {
      const orderedFields = Object.values(fields).sort((a, b) => a.order - b.order);

      orderedFields.forEach((fieldItem) => {
        if (fieldItem && fieldItem.columnTypeInGrid !== '-') {
          let item = createAggridColumn(fieldItem.name, fieldItem.columnTypeInGrid);
          item.maxWidth = 250;

          if (fieldItem.columnTypeInGrid === "Num") {
            item.valueFormatter = numberFormatterMin0Max0;
          }

          if (fieldItem.columnTypeInGrid === "2dec") {
            item.valueFormatter = numberFormatterMin2Max2;
          }

          if (fieldItem.columnTypeInGrid === "3dec") {
            item.valueFormatter = numberFormatterMin3Max3;
          }

          if (fieldItem.columnTypeInGrid === "4dec") {
            item.valueFormatter = numberFormatterMin4Max4;
          }

          if (fieldItem.columnTypeInGrid === "5dec") {
            item.valueFormatter = numberFormatterMin5Max5;
          }

          if (fieldItem.columnTypeInGrid === "Mon") {
            item.valueFormatter = numberFormatterMin3Max3;
          }

          if (fieldItem.columnTypeInGrid === "LK") {
            item.cellRenderer = 'LinkRenderer';
          }

          if (fieldItem.columnTypeInGrid === "LF") {
            item.cellRenderer = 'ExternalFileRenderer';
          }

          if (fieldItem.columnTypeInGrid === "LI") {
            item.cellRenderer = 'ExternalImageRenderer';
          }

          if (fieldItem.columnTypeInGrid === 'GC') {
            item.cellRenderer = 'CoordinatesRenderer';
          }

          if (fieldItem.columnTypeInGrid === 'IU') {
            item.cellRenderer = 'ImageRenderer';
          }

          if (fieldItem.columnTypeInGrid === 'AU') {
            item.cellRenderer = 'FileRenderer';
          }

          columnDefs.push(item);
        }
      });
    }
    return columnDefs;
  }, [fields])

  const handleDownloadStructure = () => {
    dispatch(downloadTableExcelAction(isSystemTables, { tableCode, codver, masterRowInternalCode }));
  }

  const handleOpenUploadFileDialog = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialog = () => {
    setUploadFileDialogIsOpen(false);
  }

  const handleCreate = (dataToSend) => {
    dispatch(createTableFormAction(isSystemTables, { ...dataToSend, tableCode, codver, masterRowInternalCode }))
      .then((res) => res?.status === 200 && handleCloseCreateEditDialog());
  }

  const handleModify = (dataToSend) => {
    dispatch(updateTableFormAction(isSystemTables, { ...dataToSend, tableCode, codver, masterRowInternalCode }))
      .then((res) => res?.status === 200 && handleCloseCreateEditDialog());
  }

  const handleChangeCheck = (data, newStatus) => {
    dispatch(changeCheckTableFormAction(
      isSystemTables,
      {
        masterRowInternalCode,
        internalCode: data.internalCode,
        code: data.code,
        codver: data.codver,
        vis: newStatus,
      }
    ))
      .then((res) => res?.status === 200 && handleCloseCreateEditDialog());
  }

  const handleOpenEditDialogRowClick = (row) => {
    setSelectedData(formData.find((item) => item.internalCode === row.internalCode));
    setEditDialogIsOpen(true);
  }

  const handleOpenDetailTableRowClick = (row) => {
    const searchArray = [
      `type=${isSystemTables ? 'S' : 'U'}`,
      `code=${detailTable?.tableCode}`,
      `codver=${detailTable?.codver}`,
      `masterRowInternalCode=${row.internalCode}`,
      `codeMasterTable=${tableCode}`,
      `codverMasterTable=${codver}`,
      'hideGoBack=N',
    ];

    history.push({
      path: isSystemTables ? paths.systemTables : paths.userTables,
      search: searchArray.join('&'),

    })
  }

  const handleOpenDetailTableByButton = (gridRef) => {
    let rows = gridRef.api.getSelectedRows();
    if (rows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    handleOpenDetailTableRowClick(rows[0]);
  }

  const state = {
    editDialogIsOpen,
    createDialogIsOpen,
    selectedData,
    uploadFileDialogIsOpen,
  }

  const handlers = {
    handleCreate,
    handleModify,
    handleChangeCheck,
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialogRowClick,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleDownloadStructure,
    handleOpenUploadFileDialog,
    handleCloseUploadFileDialog,
    handleOpenDetailTableByButton,
    handleOpenDetailTableRowClick,
  }

  return {
    level,
    dateFormat,
    process,
    addCodemps,
    gridData,
    detailTable,
    columnDefs,
    hasAggridColumnWithRenderer,
    fields,
    isLoading,
    state,
    handlers,
  }
}

export default useSystemTablesForm;