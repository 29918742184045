import React from 'react';
import * as actionTypes from "./actionTypes";
import {
  getSystemTablesDefinitionAPI,
  deleteSystemTableDefinitionAPI,
  createSystemTableDefinitionAPI,
  updateSystemTableDefinitionAPI,
  duplicateSystemTableDefinitionAPI,
  exportSystemTableDefinitionAPI,
  importSystemTableDefinitionAPI,
  getUserTablesDefinitionAPI,
  deleteUserTableDefinitionAPI,
  createUserTableDefinitionAPI,
  updateUserTableDefinitionAPI,
  duplicateUserTableDefinitionAPI,
  exportUserTableDefinitionAPI,
  importUserTableDefinitionAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { openDialogAction } from "@icarius-common/dialog/actions";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "No puede existir más de una tabla con el mismo nombre y código de versión";
      break;
    case "IN_USE":
      errorString = 'La tabla de detalle no puede seleccionarse porque ya se encuentra en uso.';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getTablesDefinitionAction = (isSystemTables) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    const apiToUse = isSystemTables ? getSystemTablesDefinitionAPI : getUserTablesDefinitionAPI;

    let response = await apiToUse();
    let data = response.data && response.data.data;
    let codeList = response.data && response.data.codeList;
    let accessTypeList = response.data && response.data.accessTypeList;
    let processList = response.data && response.data.processList;
    let detailTableList = response.data && response.data.detailTableList;
    let userMenuList = response.data && response.data.userMenuList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        codeList,
        accessTypeList,
        processList,
        detailTableList,
        userMenuList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteTableDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    const apiToUse = isSystemTables ? deleteSystemTableDefinitionAPI : deleteUserTableDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: `La tabla de ${isSystemTables ? 'sistema' : 'usuario'} se ha eliminado correctamente`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createTableDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    const apiToUse = isSystemTables ? createSystemTableDefinitionAPI : createUserTableDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: `La tabla de ${isSystemTables ? 'sistema' : 'usuario'} se ha creado correctamente`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateTableDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    const apiToUse = isSystemTables ? updateSystemTableDefinitionAPI : updateUserTableDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: `La tabla de ${isSystemTables ? 'sistema' : 'usuario'} se ha actualizado correctamente`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateTableDefinitionAction = (isSystemTables, dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    const apiToUse = isSystemTables ? duplicateSystemTableDefinitionAPI : duplicateUserTableDefinitionAPI;

    let response = await apiToUse(dataToSend);
    let data = response.data && response.data.data;
    let invalidFields = response.data && response.data.invalidFields;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    if (invalidFields.length) {
      const msgNode = invalidFields.map((item, index) => {
        return (
          <div key={index}>
            {item}
            {Boolean(index !== (invalidFields.length - 1)) && <br />}
          </div>
        )
      });
      dispatch(openDialogAction({ title: 'Campos que no se pudieron duplicar', acceptOnly: true, msg: msgNode }))
    }

    dispatch(openSnackbarAction({ msg: `La tabla de ${isSystemTables ? 'sistema' : 'usuario'} se ha duplicado correctamente`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const exportTableDefinitionAction = (isSystemTables, query) => async (dispatch) => {
  dispatch({ type: actionTypes.IMPORT_EXPORT });
  try {
    const apiToUse = isSystemTables ? exportSystemTableDefinitionAPI : exportUserTableDefinitionAPI;

    const response = await apiToUse(query);
    dispatch({ type: actionTypes.IMPORT_EXPORT_FULFILLED });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.IMPORT_EXPORT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const importTableDefinitionAction = (isSystemTables, query) => async (dispatch) => {
  dispatch({ type: actionTypes.IMPORT_EXPORT });
  try {
    const apiToUse = isSystemTables ? importSystemTableDefinitionAPI : importUserTableDefinitionAPI;

    const response = await apiToUse(query);
    dispatch({ type: actionTypes.IMPORT_EXPORT_FULFILLED });
    dispatch(openSnackbarAction({ msg: 'Definición de tabla importada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.IMPORT_EXPORT_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};