import React, { useState } from "react";
import { Grid, MenuItem, Tooltip, IconButton, Card } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon } from "@icarius-icons";
import { Form } from "react-final-form";
import { TextField, Select } from "mui-rff";
import { isKeyPresent, getValueFromKey } from "@icarius-utils/localStorage";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const gridStyle = { height: 75, padding: "0px 10px" };

const TotemItem = (props) => {

  const {
    isEdit,
    data,
    deleteHandle,
    workplaces,
    index,
    color,
    updateTotem,
    addTotem,
  } = props;

  const [isOpen, setIsOpen] = useState(!isEdit);

  const dispatch = useDispatch();

  const validateAndCreate = (data) => {
    if (data.workplace && data.workplace > 0 && data.code) {
      if (isEdit) {
        updateTotem(data)
      } else {
        addTotem(data);
      }
      handleClosePublication();
    } else {
      dispatch(openSnackbarAction({ msg: "Todos los datos son obligatorios", severity: 'warning' }));
    }
  }

  const initialValues = { code: data.code, workplace: data.workplace_id, isEdit: isEdit, index: index };

  const handleClosePublication = () => {
    isEdit && setIsOpen(prev => !prev);
  }

  return (
    <Form
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={initialValues}
      onSubmit={validateAndCreate}
      render={({ handleSubmit, form, submitting, pristine, values, active }) => {
        window.setFormValue = form.mutators.setValue;
        return (
          <Grid container item xs={12} style={{ margin: "10px 0px" }}>
            <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || !isEdit) ? color : "", color: (isOpen || !isEdit) && 'white' }}>
              <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
                <Grid container item xs={11} style={{ cursor: isEdit ? "pointer" : "" }} onClick={handleClosePublication}>
                  {
                    !isEdit ?
                      <>
                        <DataContainer lg={12} sm={12} md={12} text={'Creación de tótem'} />
                      </>
                      :
                      <>
                        <DataContainer sm={12} md={3} lg={3} text={data.code} />
                        <DataContainer sm={12} md={3} lg={3} text={workplaces.find(item => item.id === data.workplace_id)?.value || ''} />
                        {
                          isKeyPresent("assistance-totem-id") && getValueFromKey("assistance-totem-id") === data.code &&
                          <DataContainer sm={12} md={6} lg={6} text={'Asociado a este equipo'} />
                        }
                      </>
                  }
                </Grid>

                <Grid container direction="row" item xs={1} justify="center">
                  <Tooltip title={getLocalizedString("delete")}>
                    <IconButton size={"small"} disableRipple onClick={() => isEdit ? deleteHandle(data, isEdit, index) : deleteHandle(values, false, index)}>
                      <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Card>
            {
              isOpen &&
              <form onSubmit={handleSubmit} noValidate style={{ width: "100%" }}>
                <Grid container item xs={12} style={{ paddingTop: 10 }}>
                  <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                    <TextField
                      required
                      label={getLocalizedString("totemID")}
                      name={"code"}
                      margin={"none"}
                      disabled={isEdit ? true : false}
                      onInput={e => e.target.value = e.target.value.toString().slice(0, 19)}
                      placeholder={getLocalizedString("totemCode")}
                    />
                  </Grid>
                  <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
                    <Select
                      required
                      label={getLocalizedString("workplace")}
                      name={"workplace"}
                      formControlProps={{
                        margin: "none",
                      }}>
                      {
                        workplaces?.map((subItem, index) => (
                          <MenuItem
                            className={"whiteText"}
                            key={index}
                            value={subItem.id}>
                            {subItem.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>
                  <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
                    <ButtonDialogAction isAccept type="submit" text={getLocalizedString("save")} />
                  </Grid>
                </Grid>
              </form>
            }
          </Grid>
        )
      }}
    />
  );
}

export default TotemItem;
