import paths from "@icarius-localization/paths";
import {
	numberFormatterMin0Max3AllowsEmpty,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.taxTable],
        config: {
            headerName: "Tasa",
            field: "rate",
            cellEditor: "asyncValidationCodeEditor",
            cellEditorParams: {
                condition: (value) => {
                    return value && value.length < 20;
                }
            },
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                // we put checkbox on the name if we are not doing grouping
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
            isObligatory: true,
            maxLength: 20,
            minLength: 1
        }
    },
    {
        pages: [paths.taxTable],
        config: {
            headerName: "Hasta",
            field: "to",
            filterParams: numberFilterParams,
            valueFormatter: numberFormatterMin0Max3AllowsEmpty,
            isNumeric: true,
            isObligatory: true,
            dataType: "number"
        }
    },
    {
        pages: [paths.taxTable],
        config: {
            headerName: "Rebaja",
            field: "reduction",
            filterParams: numberFilterParams,
            valueFormatter: numberFormatterMin0Max3AllowsEmpty,
            isNumeric: true,
            isObligatory: true,
            dataType: "number"
        }
    },
    {
        pages: [paths.taxTable],
        config: {
            headerName: "Factor",
            field: "factor",
            filterParams: numberFilterParams,
            valueFormatter: numberFormatterMin0Max3AllowsEmpty,
            isNumeric: true,
            isObligatory: true,
            dataType: "number"
        }
    },
]