import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, tableCode, codver, fieldList, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'code',
      'name',
      'type',
      'size',
      'required',
      'comment',
      'order',
      'component',
      'hidden',
      'keyField',
      'disabled',
      'sql',
      'columnTypeInGrid',
      'filterField',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const fieldHasRelatedTable = Boolean(fieldList.find((item) => item.key === formData.code)?.hasRelatedTable);
    const fieldHasRelatedOptionList = Boolean(fieldList.find((item) => item.key === formData.code)?.hasRelatedOptionList);

    const schema = yup.object().shape({
      'code': yup.string().required(),
      'name': yup.string().required(),
      'type': yup.string().required(),
      'size': yup.string()
        .when('type', {
          is: (value) => value === 'A',
          then: yup.string().required()
        }),
      'required': yup.string().required(),
      'comment': yup.string(),
      'order': yup.string().required(),
      'component': yup.string().required(),
      'hidden': yup.string().required(),
      'keyField': yup.string().required(),
      'disabled': yup.string().required(),
      'sql': yup.string()
        .when('component', {
          is: (value) => value === 'L' && !fieldHasRelatedTable && !fieldHasRelatedOptionList,
          then: yup.string().required()
        }),
      'columnTypeInGrid': yup.string().required(),
      'filterField': yup.string(),
    });

    return await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const dataToSend = {
        ...formData,
        tableCode,
        codver,
      };

      isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'code') {
      if (value) {
        const fieldSelected = fieldList.find((item) => item.key === value);
        if (fieldSelected) {
          setFormData({
            ...formData,
            [fieldName]: value,
            code: fieldSelected.key,
            size: fieldSelected.size,
            type: fieldSelected.type,
            name: fieldSelected.value,
            component: '',
          });
          return;
        }
      }

      setFormData({
        ...formData,
        [fieldName]: '',
        code: '',
        size: '',
        type: '',
        name: '',
        component: '',
      })
      return;
    }

    if (fieldName === 'type') {
      setFormData({
        ...formData,
        [fieldName]: value,
        component: '',
      })
      return;
    }

    if (fieldName === 'component') {
      setFormData({
        ...formData,
        [fieldName]: value,
        sql: '',
        filterField: '',
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
