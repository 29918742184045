import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createThirdPartyAction, updateThirdPartyAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { getClassificationList } from "@icarius-pages/thirdParty/selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { country, data, handleClose } = props;
  const dispatch = useDispatch();

  const classificationList = useSelector(getClassificationList);

  const create = (dataToSend) => {
    dispatch(createThirdPartyAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateThirdPartyAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(country, data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 20 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* clasificacion */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-classification`}>{'Clasificación del tercero'}</InputLabel>
            <Select
              value={formData.classification}
              labelId={`label-classification`}
              id={`select-classification`}
              onChange={(e) => setFormValue(e.target.value, "classification")}
              margin={"none"}
            >
              {
                classificationList && classificationList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* address */}
          <TextField
            fullWidth
            margin={"none"}
            label={'Dirección'}
            value={formData.address}
            onChange={(e) => setFormValue(e.target.value, "address")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        {
          country === "CL" &&
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* previredCode */}
            <TextField
              fullWidth
              type='number'
              margin={"none"}
              label={'Código de Previred'}
              value={formData.previredCode}
              onChange={(e) => setFormValue(e.target.value, "previredCode")}
              inputProps={{ maxLength: 6 }}
            />
          </Grid>
        }
        {
          country === "CL" &&
          <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* lreCode */}
            <TextField
              fullWidth
              type='number'
              margin={"none"}
              label={'Código LRE'}
              value={formData.lreCode}
              onChange={(e) => setFormValue(e.target.value, "lreCode")}
              inputProps={{ max: 999 }}
            />
          </Grid>
        }
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
