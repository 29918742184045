import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  GridList,
  GridListTile,
  List,
  ListItem
} from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import { IMAGES_ENDPOINT } from "@icarius-connection/endpoints";
import { ImagePlaceholder } from "@icarius-icons/index";

class GroupSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { theme, data, color, isSystemTables } = this.props;
    const { width } = this.state;

    const CustomButton = withStyles({
      root: {
        marginTop: 10,
        height: 200,
        marginBottom: 10,
        borderColor: theme.theme === "dark" ? "#1c1c1c" : "#cecece",
        borderStyle: "solid",
        borderWidth: "1px",
        backgroundColor: theme.theme === "dark" ? "#444" : "white",
        color: theme.theme === "dark" ? "white" : "black",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "0 0px 0px",
      },
    })(Button);

    const StyledGridList = withStyles({
      root: {
        width: "100%",
        paddingLeft: width < 1280 ? "10px" : "0px",
        paddingRight: width < 1280 ? "10px" : "0px",
      },
    })(GridList);

    return (
      <CommonPage
        isNotGridPage
        title={`Editor de tablas de ${isSystemTables ? 'sistema' : 'usuario'}`}
      >
        {
          Boolean(Object.keys(data).length) ?
            Object.keys(data)?.map((group, index) => {
              return (
                <div style={{ marginTop: 15, width: "100%" }} key={index}>
                  <Typography style={{ marginBottom: "-10px", padding: "6px 16px", fontWeight: 400 }} className="whiteText">
                    {group}
                  </Typography>
                  <div style={{ padding: "0px 16px 6px 16px" }}>
                    <DialogTitleDivider />
                  </div>
                  <StyledGridList cellHeight={430} cols={3} >
                    {
                      data[group].map((item, index) =>
                        <GridListTile key={index} style={{ maxHeight: 220 }} cols={this.state.width > 1000 ? 1 : 3}>
                          <CustomButton onClick={() => this.props.handleOpenGrid(item)}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              {
                                Boolean(item.image) ?
                                  <img
                                    style={{ maxWidth: 200, maxHeight: 100 }}
                                    src={IMAGES_ENDPOINT + item.image}
                                    alt={"imageNotFound"}
                                  />
                                  :
                                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: 200, height: 100, backgroundColor: color }}>
                                    <ImagePlaceholder fontSize="small" style={{ fill: "var(--icons)" }} />
                                  </div>
                              }
                              <div style={{ display: "flex", flexDirection: 'column', marginTop: 5 }}>
                                <Typography className="whiteText" style={{ fontSize: 16, fontWeight: 600 }}>
                                  {item.name}
                                </Typography>
                                {`(${item.codver})`}
                              </div>
                            </div>
                          </CustomButton>
                        </GridListTile>
                      )
                    }
                  </StyledGridList>
                </div>
              )
            })
            :
            <List className="list-round-container">
              <ListItem>
                <Typography variant="h6" id={"toolbar-title"}>
                  {`No existen tablas del ${isSystemTables ? 'sistema' : 'usuario'} disponibles por el momento`}.
                </Typography>
              </ListItem>
            </List>
        }
      </CommonPage>
    );
  }
}

export default GroupSelector;
