import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./createEditDialog/index";
import useTablesFieldsDefinition from "./useTablesFieldsDefinition";

const SystemAndUserTablesFieldsDefinition = ({ isSystemTables, handleGoBack, tableData }) => {

  const {
    data,
    isLoading,
    state,
    handlers,
  } = useTablesFieldsDefinition(isSystemTables, tableData.code, tableData.codver);

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={`Definición de campos de la tabla: ${tableData.code} - ${tableData.name}`}
      gridTitle={`Definición de campos de la tabla: ${tableData.code} - ${tableData.name}`}
      columnDefPage={paths.systemTablesFieldsDefinition}
      rowData={data}
      menuItems={[goBackButton, createButton, modifyButton, deleteButton]}
      hasExpand
      hasHelp
      ignoreProcessesbyPath
    >
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedDocument}
          rows={data}
          tableCode={tableData.code}
          codver={tableData.codver}
          create={handlers.handleCreate}
          modify={handlers.handleModify}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
    </CommonPage>
  )
}

export default SystemAndUserTablesFieldsDefinition;