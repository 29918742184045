import {
  GET_TOTEMS,
  GET_TOTEMS_FULFILLED,
  GET_TOTEMS_REJECTED,
  ADD_TOTEM_FULFILLED,
  ADD_TOTEM_REJECTED,
  ADD_TOTEM,
  DELETE_REQUEST_FULFILLED,
  NEW_TOTEM,
  DELETE_TOTEM_NOT_CREATED,
  UPDATE_TOTEM_REJECTED, UPDATE_TOTEM, UPDATE_TOTEM_FULFILLED,
} from "./actionTypes";

const initialState = {
  availableTotems: [],
  alertInfo: {},
  loading: false,
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  workplaces: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case NEW_TOTEM: {
      let newTotemList = [];
      if (state.availableTotems.length > 0) {
        newTotemList = [...state.availableTotems];
        // Si ya hay uno nuevo, no creo otro
        if (newTotemList[0].code === "" && newTotemList[0].workplace_id === "" && newTotemList[0].workplace_name === "") {
          return { ...state, availableTotems: newTotemList };
        }
      }

      let newTotem = {
        code: "",
        workplace_id: "",
        workplace_name: "",
      };

      newTotemList.unshift(newTotem);
      return { ...state, availableTotems: newTotemList };
    }
    case DELETE_TOTEM_NOT_CREATED: {
      const arrResultante = state.availableTotems.filter((item, index) => index !== action.payload);
      return {
        ...state,
        availableTotems: arrResultante,
        messageModalIsOpen: false,
      };
    }

    case GET_TOTEMS:
      return { ...state, loading: true };
    case GET_TOTEMS_FULFILLED:
      return {
        ...state,
        loading: false,
        availableTotems: action.payload.totems,
        workplaces: action.payload.workplaces,
      };
    case GET_TOTEMS_REJECTED:
      return { ...state, loading: false };
    case ADD_TOTEM_FULFILLED: {
      let newTotemList = [];
      if (state.availableTotems.length > 0) {
        newTotemList = [...state.availableTotems];
      }
      const workplace = state.workplaces.find(workplace => workplace.id === action.payload.workplace_id);

      let newTotem = {
        code: action.payload.code,
        workplace_id: action.payload.workplace_id,
        workplace_name: workplace ? workplace.name : "",
      };

      newTotemList[action.payload.index] = newTotem;

      return { ...state, availableTotems: newTotemList, loading: false };
    }
    case ADD_TOTEM:
      return { ...state, loading: true };
    case ADD_TOTEM_REJECTED:
      return { ...state, loading: false };
    case UPDATE_TOTEM:
      return { ...state, loading: true };
    case UPDATE_TOTEM_REJECTED:
      return { ...state, loading: false };
    case UPDATE_TOTEM_FULFILLED: {
      let newTotemList = [...state.availableTotems];

      const workplace = state.workplaces.find(workplace => workplace.id === action.payload.workplace_id);

      const totemIndex = state.availableTotems.findIndex(totem => totem.code === action.payload.code);

      let newTotem = {
        code: action.payload.code,
        workplace_id: action.payload.workplace_id,
        workplace_name: workplace ? workplace.name : "",
      };

      newTotemList[totemIndex] = newTotem;

      return { ...state, availableTotems: newTotemList, loading: false };
    }
    case DELETE_REQUEST_FULFILLED: {
      const arrResultante = state.availableTotems.filter(item => item.code !== action.payload.code);

      return {
        ...state,
        availableTotems: arrResultante,
        messageModalIsOpen: false,
      };
    }

    default:
      return state;
  }
}
