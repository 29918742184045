import NAME from "./constants";

export const getAddCodemps = store => store[NAME].addCodemps;
export const getIsLoading = store => store[NAME].isLoading;
export const getTables = store => store[NAME].tables;
export const getGridData = store => store[NAME].gridData;
export const getFormData = store => store[NAME].formData;
export const getFields = store => store[NAME].fields;
export const getProcess = store => store[NAME].process;
export const getDateFormat = store => store[NAME].dateFormat;
export const getIsLoadingFileUpload = store => store[NAME].isLoadingFileUpload;