import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTablesDefinitionAction, importTableDefinitionAction } from "../actions";
import {
  getLoading,
  getData,
  getCodeList,
  getAccessTypeList,
  getProcessList,
  getDetailTableList,
  getUserMenuList,
} from "../selectors";
import { getAppColor, getIsAdmin } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import { useHistory } from "react-router-dom";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { importIcariusFile } from "@icarius-utils/exportImportIcarius";
import { getFiltersIsLoading } from "@icarius-common/filtersDialog/selectors";

const SystemAndUserTablesDefinition = ({ isSystemTables, handleOpenTableFieldsPage }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const isAdmin = useSelector(getIsAdmin);

  const color = useSelector(getAppColor);
  const data = useSelector(getData);

  const codeList = useSelector(getCodeList);
  const accessTypeList = useSelector(getAccessTypeList);
  const processList = useSelector(getProcessList);
  const detailTableList = useSelector(getDetailTableList);
  const userMenuList = useSelector(getUserMenuList);
  const isLoading = useSelector(getLoading);
  const filtersIsLoading = useSelector(getFiltersIsLoading);

  useEffect(() => {
    dispatch(getTablesDefinitionAction(isSystemTables));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, isSystemTables])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  const handleImport = () => {
    const importCallback = (parsedObject) => {
      if (parsedObject.query && parsedObject.parameters) {
        dispatch(importTableDefinitionAction(isSystemTables, parsedObject))
          .then(response => {
            if (response.data.status === "OK") {
              dispatch(getTablesDefinitionAction(isSystemTables));
            }
          })
      } else {
        dispatch(openSnackbarAction({ msg: "El archivo que está intentando importar se encuentra dañado", severity: "error" }));
      }
    }

    importIcariusFile(dispatch, importCallback);
  }

  const customItems = (
    <>
      <CustomIconButton
        title={"Volver"}
        onClick={() => history.push(paths.extensibilityScreenSelector)}
        type={"goBack"}
      />
      <CustomIconButton
        title={"Importar definición de tablas"}
        onClick={handleImport}
        type={"upload"}
      />
    </>
  )

  return (
    <ABMLayout
      title={`Definición de tablas del ${isSystemTables ? 'sistema' : 'usuario'}`}
      isLoading={isLoading || filtersIsLoading}
      showCreateButton={!createIsOpen && isAdmin}
      createButtonTitle={`Nueva tabla del ${isSystemTables ? 'sistema' : 'usuario'}`}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showNoData={getDataIsEmpty()}
      noDataTitle={`No se encontraron tablas del ${isSystemTables ? 'sistema' : 'usuario'}`}
      noDataCreateTitle={`Nueva tabla del ${isSystemTables ? 'sistema' : 'usuario'}`}
      noDataOnClick={() => setCreateIsOpen(true)}
      customItems={[customItems]}
      useCustomItemsEnd
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            color={color}
            data={null}
            codeList={codeList}
            accessTypeList={accessTypeList}
            processList={processList}
            userMenuList={userMenuList}
            detailTableList={detailTableList}
            closeNew={() => setCreateIsOpen(false)}
            isAdmin={isAdmin}
            isSystemTables={isSystemTables}
          />
        </Grid>
      }
      {
        data?.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.internalCode}>
              <DataItem
                data={item}
                codeList={codeList}
                accessTypeList={accessTypeList}
                processList={processList}
                userMenuList={userMenuList}
                detailTableList={detailTableList}
                color={color}
                handleOpenTableFieldsPage={handleOpenTableFieldsPage}
                isAdmin={isAdmin}
                isSystemTables={isSystemTables}
                filtersIsLoading={filtersIsLoading}
              />
            </Grid>
          )
        })
      }
    </ABMLayout>
  )
}

export default SystemAndUserTablesDefinition;