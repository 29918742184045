import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditDialog from "./dialogs/createEditDialog/index";
import useSystemTablesForm from "./useSystemTableForm";
import SetParamDialog from "@icarius-common/setParamDialog";
import useProcessExecution from "./useProcessExecution";
import useForceRender from "@icarius-utils/hooks/useForceRender";
import UploadFileDialog from "./dialogs/uploadFileDialog";
import LinkRenderer from "@icarius-table/renderersAndFilters/linkRenderer";
import CoordinatesRenderer from "@icarius-table/renderersAndFilters/coordinatesRenderer";
import ImageRenderer from "@icarius-table/renderersAndFilters/imageRenderer";
import ExternalImageRenderer from "@icarius-table/renderersAndFilters/externalImageRenderer";
import FileRenderer from "@icarius-table/renderersAndFilters/fileRenderer";
import ExternalFileRenderer from "@icarius-table/renderersAndFilters/externalFileRenderer";
import TocIcon from '@material-ui/icons/Toc';
import TemplatesHeader from "@icarius-common/templates/components/templatesHeader";
import CreateTemplateDialog from "@icarius-common/templates/components/createTemplateDialog";
import useTemplates from "@icarius-common/templates/components/useTemplates";

const GridScreen = (props) => {

  const {
    isSystemTables,
    masterRowInternalCode,
    tableData,
    handleGoBack,
    hideGoBack,
  } = props;

  const {
    level,
    dateFormat,
    process,
    detailTable,
    addCodemps,
    gridData,
    columnDefs,
    hasAggridColumnWithRenderer,
    fields,
    isLoading,
    state,
    handlers,
  } = useSystemTablesForm(isSystemTables, tableData.code, tableData.codver, tableData.detailTable, masterRowInternalCode);

  const {
    processExecutionData,
    processExecutionFunctions,
  } = useProcessExecution(isSystemTables, process, addCodemps);

  const {
    user,
    templates,
    selectedTemplate,
    isLoadingTemplates,
    createTemplateDialogIsOpen,
    getRole,
    handleCreateTemplate,
    handleImportTemplates,
    handleExportTemplates,
    handleSelectTemplateAndUpdateGrid,
    handleOpenCreateTemplateDialog,
    handleOpenUpdateTemplateDialog,
    handleOpenDeleteTemplateDialog,
    handleCloseCreateTemplateDialog,
  } = useTemplates(`${isSystemTables ? 'SYSTEM' : 'USER'}TABLES@${tableData.code}_${tableData.codver}`);

  useForceRender(columnDefs); // sin esto, no hay acceso al gridRef hasta el proximo render

  const hasFields = Boolean(Object.keys(fields).length);
  const isDetailTable = Boolean(masterRowInternalCode);

  const detailTableButton = (gridRef) => (
    hasFields && Boolean(detailTable) &&
    <CustomIconButton
      title={'Abrir tabla detalle'}
      onClick={() => handlers.handleOpenDetailTableByButton(gridRef)}
    >
      <TocIcon />
    </CustomIconButton>
  )

  const createButton = () => (
    hasFields && tableData.editorActive === "Y" &&
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={handlers.handleOpenCreateDialog}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    hasFields && (['Y', 'M'].includes(tableData.editorActive)) &&
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    hasFields && tableData.editorActive === "Y" && tableData.canDelete &&
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const goBackButton = () => (
    (!hideGoBack || isDetailTable) &&
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />
  )

  const processButton = (gridRef) => (
    (['N', 'Y', 'M'].includes(tableData.editorActive)) && process && Boolean(Object.keys(process).length) &&
    <CustomIconButton
      title={`Ejecutar proceso: ${process.value}`}
      onClick={() => processExecutionFunctions.handleValidateAndAskProcess(gridRef)}
      type={"execute"}
    />
  )

  const exportButton = () => (
    (['N', 'Y', 'M'].includes(tableData.editorActive)) && tableData.importerActive &&
    <CustomIconButton
      title={"Exportar plantilla para importación"}
      onClick={handlers.handleDownloadStructure}
      type={"download"}
    />
  )

  const importButton = () => (
    (['N', 'Y', 'M'].includes(tableData.editorActive)) && tableData.importerActive &&
    <CustomIconButton
      title={"Importar"}
      onClick={handlers.handleOpenUploadFileDialog}
      type={"upload"}
    />
  )

  const templateHeader = (gridRef) => (
    <>
      <TemplatesHeader
        gridRef={gridRef}
        user={user}
        templates={templates}
        selectedTemplate={selectedTemplate}
        getRole={getRole}
        handleImportTemplates={handleImportTemplates}
        handleExportTemplates={handleExportTemplates}
        handleOpenCreateTemplateDialog={handleOpenCreateTemplateDialog}
        handleOpenDeleteTemplateDialog={handleOpenDeleteTemplateDialog}
        handleOpenUpdateTemplateDialog={handleOpenUpdateTemplateDialog}
        handleSelectTemplateAndUpdateGrid={handleSelectTemplateAndUpdateGrid}
      />
    </>
  )

  const child = (gridRef) =>
    <>
      {
        createTemplateDialogIsOpen &&
        <CreateTemplateDialog
          open={createTemplateDialogIsOpen}
          level={user.level}
          handleCreateTemplate={(role, name) => handleCreateTemplate(gridRef, role, name)}
          handleCloseDialog={handleCloseCreateTemplateDialog}
        />
      }
      {
        (state.createDialogIsOpen || state.editDialogIsOpen) &&
        <CreateEditDialog
          open={(state.createDialogIsOpen || state.editDialogIsOpen)}
          isLoading={isLoading}
          data={state.selectedData}
          tableData={tableData}
          fields={fields}
          level={level}
          create={handlers.handleCreate}
          modify={handlers.handleModify}
          changeCheck={handlers.handleChangeCheck}
          handleClose={handlers.handleCloseCreateEditDialog}
          isSystemTables={isSystemTables}
        />
      }
      {
        processExecutionData.paramDialogIsOpen &&
        <SetParamDialog
          open={processExecutionData.paramDialogIsOpen}
          params={processExecutionData.paramsToUse}
          handleClose={processExecutionFunctions.handleCloseParamDialog}
          handleAgree={processExecutionFunctions.executeProcess}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialog}
          tableCode={tableData.code}
          codver={tableData.codver}
          masterRowInternalCode={masterRowInternalCode}
          isSystemTables={isSystemTables}
        />
      }
    </>

  const handleRowClick = (row) => {
    if (hasAggridColumnWithRenderer) return; // tiene columnas con onClick propio, no hacer nada

    if (Boolean(tableData.editorActive === "V") && Boolean(detailTable)) {
      handlers.handleOpenDetailTableRowClick(row.data);
      return;
    }

    if (Boolean(tableData.editorActive !== "N")) {
      handlers.handleOpenEditDialogRowClick(row.data);
      return;
    }
  }

  return (
    <CommonPage
      isLoading={isLoading || isLoadingTemplates}
      title={`${tableData.name}: ${tableData.codver}`}
      gridTitle={`${tableData.name}: ${tableData.codver}`}
      rowData={hasFields ? gridData : []}
      ownColumnDef={columnDefs}
      dateFormat={dateFormat}
      menuItems={[goBackButton, detailTableButton, createButton, modifyButton, deleteButton, processButton, exportButton, importButton]}
      customHeader={templateHeader}
      handleRowClick={handleRowClick}
      hasExpand
      hasHelp
      pathToUseForProcess={`${tableData.code}&codver=${tableData.codver}`}
      codeFieldName={"internalCode"}
      addCodemps={addCodemps}
      frameworkComponents={{ LinkRenderer, CoordinatesRenderer, ExternalImageRenderer, ImageRenderer, ExternalFileRenderer, FileRenderer }}
    >
      {child}
    </CommonPage>
  )
}

export default GridScreen;